import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, StaticImage } from "gatsby-plugin-image";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import React from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { InlineWidget } from "react-calendly";
import { Helmet } from "react-helmet";
import { FaFacebook, FaInfoCircle, FaWhatsapp } from "react-icons/fa";
import { SiGooglemybusiness } from "react-icons/si";
import ContactForm from "../components/contactForm";
import Hero from "../components/hero";
import Layout from "../components/layout";
import { Button, OverlayTrigger, Popover } from "react-bootstrap";
import parse from "html-react-parser";
import PricingCard from "../components/pricing-card";

const PricingPage = () => {
	const data = useStaticQuery(graphql`
		query {
			heroImg: wpMediaItem(title: { eq: "Hero-Homepage" }) {
				altText

				mediaDetails {
					height
					width
				}
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}

			guaranteeImg: wpMediaItem(title: { eq: "A Bridge to Completion" }) {
				altText
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}

			wpSeoPage(title: { eq: "Contact Us" }) {
				contentForHeadTag {
					content
				}
				seo {
					metaDesc
					title
					twitterImage {
						altText
						mediaDetails {
							height
							width
						}
						link
						gatsbyImage(
							width: 1920
							quality: 75
							formats: [WEBP]
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
					opengraphTitle
					opengraphDescription
				}
			}
			wpContactPage {
				contactPageFields {
					bookAMeetingSection {
						title
						description
					}
					heroSection {
						title
						description
					}
					meetingsOnlineSection {
						title
						description
					}
				}
			}

			site {
				siteMetadata {
					siteUrl
				}
			}
		}
	`);

	const siteUrl = data.site.siteMetadata.siteUrl;
	const {
		wpContactPage: {
			contactPageFields: {
				bookAMeetingSection,
				heroSection,
				meetingsOnlineSection,
			},
		},
	} = data;

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: "2",
				name: "Pricing",
				item: {
					url: `${siteUrl}/pricing`,
					id: `${siteUrl}/pricing`,
				},
			},
		],
	};

	return (
		<>
			<Helmet>
				{" "}
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>
			{/* <LocalBusinessSchema /> */}
			<GatsbySeo
				title="Pricing and packages"
				description="Pricing for RJM Digital Websites"
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}/pricing`,
					title: "Who much does a Headless Wordpress cost?",
					description: "Pricing chart of Headless WordPress websites",
					images: [
						{
							url: `${data.wpSeoPage.seo.twitterImage.link}`,
							width: `${data.wpSeoPage.seo.twitterImage.mediaDetails.width}`,
							height: `${data.wpSeoPage.seo.twitterImage.mediaDetails.height}`,
							alt: `${data.wpSeoPage.seo.twitterImage.altText}`,
						},
					],
				}}
			/>

			<Layout>
				<section>
					<Hero
						
						button1={null}
						button2={null}
						button3={null}
						backgroundImage={data.heroImg.gatsbyImage}
						title={`PRICING & \n<span class="text-secondary">PACKAGES</span>`}
						description="Explore our Headless website packages and get in touch to start your project."
					/>
				</section>
                <section className="py-5 py-lg-7" id="pricing">
					{" "}
					<Container className="pricing-heading position-relative">
						<Row>
							<Col>
								<h2 className="pb-5 text-center text-primary display-5">
									Pricing
								</h2>
							</Col>
						</Row>
						<Row className={` justify-content-center `}>
							<PricingCard
								
								btnColour="secondary"
								title="Initium"
								subText="For businesses and start-ups launching smaller projects, individual campaigns, or landing pages."
								price="$3,995"
								monthly
								setupFee="$135"
								features={[
									<span className="d-flex align-items-center">
									<p className="pb-0 mb-0 d-inline-block ssp-regular">
										Unlimited page website{" "}
										<OverlayTrigger
											placement="top"
											overlay={
												<Popover id={`popover-positioned-top`}>
													<Popover.Body>Our websites are powered by reusable ACF and ReactJS components, so you can create and build as many pages as you want, without requiring RJM support</Popover.Body>
												</Popover>
											}
										>
											<FaInfoCircle
												style={{ width: "10px", color: "#40B4E5" }}
												className="ms-2 d-inline-block"
											/>
										</OverlayTrigger>
									</p>
								</span>,
									<span className="d-flex align-items-center">
										<p className="pb-0 mb-0 d-inline-block ssp-regular">
											Up to 5 custom pages designed{" "}
											<OverlayTrigger
												placement="top"
												overlay={
													<Popover id={`popover-positioned-top`}>
														<Popover.Body>Typical pages include: Home, About, Services, Blog and Contact</Popover.Body>
													</Popover>
												}
											>
												<FaInfoCircle
													style={{ width: "10px", color: "#40B4E5" }}
													className="ms-2 d-inline-block"
												/>
											</OverlayTrigger>
										</p>
									</span>,									
									"Mobile-responsive design",
									<span className="d-flex align-items-center">
										<p className="pb-0 mb-0 d-inline-block ssp-regular">
											Fully managed website, including maintenance{" "}
											<OverlayTrigger
												placement="top"
												overlay={
													<Popover id={`popover-positioned-top`}>
														<Popover.Body>Excludes domain</Popover.Body>
													</Popover>
												}
											>
												<FaInfoCircle
													style={{ width: "10px", color: "#40B4E5" }}
													className="ms-2 d-inline-block"
												/>
											</OverlayTrigger>
										</p>
									</span>,		
									"GatsbyJS frontend",							
									"Headless WordPress Content Management System",									
									"Unlimited images, audio and file content",
									"Fast and secure web hosting on Netlify",
									"72 hour support response time",
									"SSL certificate",
									"Custom forms using Formspree",
									"Google Tag Manager and Google Analytics",
								]}
							/>
							<PricingCard
								
								btnColour="dark-blue"
								middle
								title="Proventus"
								subText={
									<span>
										For businesses ready to expand their online presence and scale.
									</span>
								}
								price="$6,995"
								monthly
								setupFee="$245"
								features={[
									<span>Everything in <strong>Initium</strong></span>,
									<span className="d-flex align-items-center">
										<p className="pb-0 mb-0 d-inline-block ssp-regular">
											Up to 10 custom pages designed{" "}
											<OverlayTrigger
												placement="top"
												overlay={
													<Popover id={`popover-positioned-top`}>
														<Popover.Body>Typical pages include: Home, About, Services, Blog, Contact, Case studies, FAQ, Portfolio/Customers, Google Ad and local SEO pages </Popover.Body>
													</Popover>
												}
											>
												<FaInfoCircle
													style={{ width: "10px", color: "#40B4E5" }}
													className="ms-2 d-inline-block"
												/>
											</OverlayTrigger>
										</p>
									</span>,
									"Fully bespoke design tailored to your brand",
									<span className="d-flex align-items-center">
									<p className="pb-0 mb-0 d-inline-block ssp-regular">
										Additional 10 custom components designed{" "}
										<OverlayTrigger
											placement="top"
											overlay={
												<Popover id={`popover-positioned-top`}>
													<Popover.Body>Includes all pre-built RJM components </Popover.Body>
												</Popover>
											}
										>
											<FaInfoCircle
												style={{ width: "10px", color: "#40B4E5" }}
												className="ms-2 d-inline-block"
											/>
										</OverlayTrigger>
									</p>
								</span>,
								<span className="d-flex align-items-center">
								<p className="pb-0 mb-0 d-inline-block ssp-regular">
									Advanced SEO Implementation{" "}
									<OverlayTrigger
										placement="top"
										overlay={
											<Popover id={`popover-positioned-top`}>
												<Popover.Body>We will create the Meta TItle, Meta Description, Schema Mark-up and Open Graph content for each page</Popover.Body>
											</Popover>
										}
									>
										<FaInfoCircle
											style={{ width: "10px", color: "#40B4E5" }}
											className="ms-2 d-inline-block"
										/>
									</OverlayTrigger>
								</p>
							</span>,
										
								<span className="d-flex align-items-center">
								<p className="pb-0 mb-0 d-inline-block ssp-regular">
									Zapier integrations for automated workflows{" "}
									<OverlayTrigger
										placement="top"
										overlay={
											<Popover id={`popover-positioned-top`}>
												<Popover.Body>Connect form submissions and other data to your CRM and other marketing tools</Popover.Body>
											</Popover>
										}
									>
										<FaInfoCircle
											style={{ width: "10px", color: "#40B4E5" }}
											className="ms-2 d-inline-block"
										/>
									</OverlayTrigger>
								</p>
							</span>,						
							"48 hour support response time",
							<span className="d-flex align-items-center">
							<p className="pb-0 mb-0 d-inline-block ssp-regular">
								Copywriting support{" "}
								<OverlayTrigger
									placement="top"
									overlay={
										<Popover id={`popover-positioned-top`}>
											<Popover.Body>Complete website copywriting, including 2 revisions</Popover.Body>
										</Popover>
									}
								>
									<FaInfoCircle
										style={{ width: "10px", color: "#40B4E5" }}
										className="ms-2 d-inline-block"
									/>
								</OverlayTrigger>
							</p>
						</span>
																
								]}
							/>
							<PricingCard
								
								btnColour="light-blue"
								title="Optimus"
								subText="For established businesses and enterprises needing an advanced solution."
								price="$11,995"
								monthly
								setupFee="$395"
								features={[
									<span>Everything in <strong>Proventus</strong></span>,
									<span className="d-flex align-items-center">
										<p className="pb-0 mb-0 d-inline-block ssp-regular">
											Up to 50 custom pages and components{" "}
											<OverlayTrigger
												placement="top"
												overlay={
													<Popover id={`popover-positioned-top`}>
														<Popover.Body>Includes all RJM pre-built components</Popover.Body>
													</Popover>
												}
											>
												<FaInfoCircle
													style={{ width: "10px", color: "#40B4E5" }}
													className="ms-2 d-inline-block"
												/>
											</OverlayTrigger>
										</p>
									</span>,																
									

									"24 SLA support response time",							
									<span className="d-flex align-items-center">
									<p className="pb-0 mb-0 d-inline-block ssp-regular">
										Full copywriting included{" "}
										<OverlayTrigger
											placement="top"
											overlay={
												<Popover id={`popover-positioned-top`}>
													<Popover.Body>Complete website copywriting, including 3 revisions</Popover.Body>
												</Popover>
											}
										>
											<FaInfoCircle
												style={{ width: "10px", color: "#40B4E5" }}
												className="ms-2 d-inline-block"
											/>
										</OverlayTrigger>
									</p>
								</span>,	
								]}
							/>
						</Row>
					</Container>
				</section>

				<section id="guarantee" className="py-5 bg-light-grey py-lg-7">
					<Container>
						<Row className="align-items-center justify-content-between gx-6">
						<Col lg={5}>
								<div
									className="mt-5 mt-lg-0"
									style={{
										overflow: "hidden",
										borderRadius: "15px",
										boxShadow: "0px 3px 99px #00000027",
									}}
								>
									<GatsbyImage
										className="w-100 "
										image={data.guaranteeImg.gatsbyImage}
										alt={data.guaranteeImg.altText}
									/>
								</div>
							</Col>
							<Col lg={6} className="">
								<h2 className="mb-5 display-5 text-primary">
								Love Your New Website— Or Your Money Back!
								</h2>

								<p>Tired of bland, cookie-cutter websites that barely move the needle?</p>								
								<p>With our “Love It or Your Money Back Guarantee,” you’re not risking a single cent. We deliver a high-performance, highly secure and scalbale website crafted specifically for growing and ambitious companies to attract leads, build trust, and boost your bottom line. </p>
								<p>If you’re not over-the-moon, thrilled with our design or you feel it doesn’t set you apart, we’ll hand back every cent you paid. Why settle for mediocrity when you can demand excellence—guaranteed?</p>

								<Button
									className="px-4 py-2 mt-4  w-100 cta-btn w-md-auto fs-5 white-link-yellow"
									variant="primary"
									href="#form"
								>
									Talk to an expert
								</Button>
							</Col>
						
						</Row>
					</Container>
				</section>	
				


				<section className="pt-5 pt-lg-7 pb-xl-7 bg-light-grey" id="form">
					<Container>
						<Row>
							<Col className="text-center">
								{" "}
								<div>{parse(bookAMeetingSection.title)}</div>
								<p>{bookAMeetingSection.description}</p>
							</Col>
						</Row>
						<Row>
							<Col className="d-xl-none">
								<InlineWidget
									className="calender"
									styles={{ minWidth: "100%", height: "1150px" }}
									url="https://calendly.com/robbie-rjm-digital/rjm-discovery-meeting?hide_gdpr_banner=1"
								/>
							</Col>
							<Col className="d-none d-xl-block">
								<InlineWidget
									className="calender"
									styles={{ minWidth: "100%", height: "700px" }}
									url="https://calendly.com/robbie-rjm-digital/rjm-discovery-meeting?hide_gdpr_banner=1"
								/>
							</Col>
						</Row>
					</Container>
				</section>
			</Layout>
		</>
	);
};

export default PricingPage;
