import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { GatsbyImage } from "gatsby-plugin-image";
import { MdAlternateEmail } from "react-icons/md";
import { RiLinkedinFill } from "react-icons/ri";

const ThreeColImage = () => {
	const data = useStaticQuery(graphql`
		query {
			img1: wpMediaItem(title: { eq: "The Rate Reboot" }) {
				altText
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}
			img2: wpMediaItem(title: { eq: "Live and Let Leverage" }) {
				altText
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}
			img3: wpMediaItem(title: { eq: "A Bridge to Completion" }) {
				altText
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}
			site {
				siteMetadata {
					siteUrl
				}
			}
		}
	`);

	return (
		<Container fluid className="px-0 mx-0">
			<Row className="g-0 px-0 mx-0">
				<Col lg={4} className=" px-0 mx-0">
					<Row className="g-0 px-0 mx-0">
						<Col md={12}>
							<GatsbyImage
								image={data.img1.gatsbyImage}
								alt={data.img1.altText}
								className="w-100"
							/>
						</Col>						
					</Row>
				</Col>
				<Col lg={4} className=" px-0 mx-0">
					<Row className="g-0 px-0 mx-0">
						<Col md={12}>
							<GatsbyImage
								image={data.img2.gatsbyImage}
								alt={data.img2.altText}
								className="w-100"
							/>
						</Col>						
					</Row>
				</Col>
				<Col lg={4} className=" px-0 mx-0">
					<Row className="g-0 px-0 mx-0">
						<Col md={12}>
							<GatsbyImage
								image={data.img3.gatsbyImage}
								alt={data.img3.altText}
								className="w-100"
							/>
						</Col>						
					</Row>
				</Col>
			</Row>
		</Container>
	);
};

export default ThreeColImage;
