import { Link, graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, StaticImage } from "gatsby-plugin-image";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import React from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { InlineWidget } from "react-calendly";
import { Helmet } from "react-helmet";
import Hero from "../components/hero";
import Layout from "../components/layout";
import OSSection from "../components/other-services-section";
import WhyChooseRJMSection from "../components/why-choose-rjm-section";
import yellowOne from "../images/1-yellow.svg";
import yellowTwo from "../images/2-yellow.svg";
import yellowThree from "../images/3-yellow.svg";

const GoogleAdsPage = () => {
  const data = useStaticQuery(graphql`
    query {
      heroImg: wpMediaItem(title: { eq: "Hero-Homepage" }) {
        altText
        mediaDetails {
          height
          width
        }
        link
        gatsbyImage(
          width: 1920
          quality: 75
          formats: [WEBP]
          layout: CONSTRAINED
          placeholder: BLURRED
        )
      }
      sideImg1: wpMediaItem(title: { eq: "Google-image" }) {
        altText
        link
        gatsbyImage(
          width: 1920
          quality: 75
          formats: [WEBP]
          layout: CONSTRAINED
          placeholder: BLURRED
        )
      }
      ninjaImg: wpMediaItem(title: { eq: "Ninjaarena-fullpage" }) {
        altText
        link
        gatsbyImage(
          width: 1920
          quality: 75
          formats: [WEBP]
          layout: CONSTRAINED
          placeholder: BLURRED
        )
      }
      ninjaLogoImg: wpMediaItem(title: { eq: "ninjaarenalogo1" }) {
        altText
        link
        gatsbyImage(
          width: 1920
          quality: 75
          formats: [WEBP]
          layout: CONSTRAINED
          placeholder: BLURRED
        )
      }
      weddingImg: wpMediaItem(title: { eq: "weddingcomanylogo" }) {
        altText
        link
        gatsbyImage(
          width: 1920
          quality: 75
          formats: [WEBP]
          layout: CONSTRAINED
          placeholder: BLURRED
        )
      }

      needHelpImage: wpMediaItem(title: { eq: "Blog Creation landing page" }) {
        altText
        link
        gatsbyImage(
          width: 1920
          quality: 75
          formats: [WEBP]
          layout: CONSTRAINED
          placeholder: BLURRED
        )
      }
      adsImage: wpMediaItem(title: { eq: "ads-image" }) {
        altText
        link
        gatsbyImage(
          width: 1920
          quality: 75
          formats: [WEBP]
          layout: CONSTRAINED
          placeholder: BLURRED
        )
      }
      wpSeoPage(title: { eq: "Blog Creation" }) {
        contentForHeadTag {
          content
        }
        seo {
          metaDesc
          title
          twitterImage {
            altText
            mediaDetails {
              height
              width
            }
            link
            gatsbyImage(
              width: 1920
              quality: 75
              formats: [WEBP]
              layout: CONSTRAINED
              placeholder: BLURRED
            )
          }
          opengraphTitle
          opengraphDescription
        }
      }
      icon: wpMediaItem(title: { eq: "icon" }) {
        link
      }

      site {
        siteMetadata {
          siteUrl
        }
      }
    }
  `);

  const siteUrl = data.site.siteMetadata.siteUrl;
  const schema = data.wpSeoPage.contentForHeadTag.content;

  const breadcrumb = {
    "@context": "http://schema.org",
    "@type": "BreadcrumbList",
    itemListElement: [
      {
        "@type": "ListItem",
        position: "1",
        name: "Home",
        item: {
          url: `${siteUrl}`,
          id: `${siteUrl}`,
        },
      },
      {
        "@type": "ListItem",
        position: "2",
        name: "Blog Creation",
        item: {
          url: `${siteUrl}/blog-creation`,
        },
      },
    ],
  };

  return (
    <>
      <Helmet>
        {" "}
        <script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
      </Helmet>
      <Helmet>
        {" "}
        <script type="application/ld+json">{schema}</script>
      </Helmet>
      {/* <LocalBusinessSchema /> */}
      <GatsbySeo
        title={data.wpSeoPage.seo.title}
        description={data.wpSeoPage.seo.metaDesc}
        language="en"
        openGraph={{
          type: "website",
          url: `${siteUrl}/blog-creation`,
          title: `${data.wpSeoPage.seo.opengraphTitle}`,
          description: `${data.wpSeoPage.seo.opengraphDescription}`,
          images: [
            {
              url: `${data.wpSeoPage.seo.twitterImage.link}`,
              width: `${data.wpSeoPage.seo.twitterImage.mediaDetails.width}`,
              height: `${data.wpSeoPage.seo.twitterImage.mediaDetails.height}`,
              alt: `${data.wpSeoPage.seo.twitterImage.altText}`,
            },
          ],
        }}
      />

      <Layout>
        <section>
          <Hero
            button1="primary-link-dark"
            button1Text="Talk to an expert"
            button1Link="#form"
            button1Var="white"
            button2="white-link-yellow"
            button2Text="Get in touch"
            button2Link="/contact-us"
            button2Var="primary"
            button3={null}
            backgroundImage={data.heroImg.gatsbyImage}
            title={`Blog \n<span class="text-secondary">Creation</span>`}
            description="Become the thought leader in your industry by writing engaging blogs."
          />
        </section>
        {/* <section className="py-5 py-lg-7 bg-light-grey d-none d-lg-block">
					<Container className="p-5 bg-white">
						<Row className="justify-content-center gy-4">
							<Col className="text-center" md={4} lg={true}>
								<a
									className="text-primary ssp-regular fs-5 border-top-yellow"
									href="#intro"
								>
									Introduction
								</a>
							</Col>
							<Col className="text-center" md={4} lg={true}>
								<a
									className="text-primary ssp-regular fs-5 border-top-yellow"
									href="#benefits"
								>
									Benefits
								</a>
							</Col>
							<Col className="text-center" md={4} lg={true}>
								<a
									className="text-primary ssp-regular fs-5 border-top-yellow"
									href="#results"
								>
									Results achieved
								</a>
							</Col>
							<Col className="text-center" md={4} lg={true}>
								<a
									className="text-primary ssp-regular fs-5 border-top-yellow"
									href="#approach"
								>
									Our Approach
								</a>
							</Col>
							<Col className="text-center" md={4} lg={true}>
								<a
									className="text-primary ssp-regular fs-5 border-top-yellow"
									href="#faq"
								>
									FAQ's
								</a>
							</Col>
						</Row>
					</Container>
				</section> */}
        <section id="intro" className="bg-light-grey py-5 py-lg-7">
          <Container>
            <Row className="align-items-center gx-6 gx-lg-6 gx-xl-7">
              <Col lg={6} className=" mb-5 mb-lg-0">
                <h2 className="display-5 text-primary mb-5">
                  Create informative blogs with rich content to attract your
                  ideal clients
                </h2>
                <p>
                  Adding a blog section to your website can help to enhance your
                  customers' engagement, through valuable content displayed in a
                  variety of unique ways. Writing and posting blogs on a regular
                  basis can allow you to share informative material with your
                  users, ultimately enabling you to connect with your audience
                  and establish your website as a go-to source of information.
                </p>
                <p>
                  Build trust with your prospects and clients by providing them
                  with new blog articles containing valuable content that
                  demonstrates your expertise, allowing you to build a resource
                  hub for customers in your industry. Achieve more from your
                  blogs with RJM Digital.
                </p>
                <Button
                  className=" cta-btn w-100 w-md-auto mt-4 mb-5 mb-md-0 fs-5 white-link-yellow py-2 px-4 "
                  variant="primary"
                  href="#form"
                >
                  Talk to an expert
                </Button>
                <Button
                  className=" w-100 cta-btn w-md-auto fs-5 ms-md-4 d-lg-none d-xl-inline-block white-link-yellow py-2 px-4  mb--0 mt-md-4 "
                  variant="primary"
                  as={Link}
                  to="/contact-us"
                >
                  Get in touch
                </Button>
              </Col>
              <Col lg={6}>
                <div
                  style={{
                    overflow: "hidden",
                    borderRadius: "15px",
                    boxShadow: "0px 3px 99px #00000027",
                  }}
                >
                  <GatsbyImage
                    className="w-100"
                    image={data.needHelpImage.gatsbyImage}
                    alt={data.needHelpImage.altText}
                  />
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        <section id="benefits" className="py-5 py-lg-7 bg-dark-grey">
          <Container className="mb-8">
            <Row className="justify-content-between align-items-center">
              <Col md={6} lg={8}>
                <h2 className="display-4 text-uppercase ssp-bold text-white mb-lg-5">
                  Why you should add a blog
                  <span>
                    <br />{" "}
                    <span className="text-secondary">to your website</span>
                  </span>
                </h2>
              </Col>
              {/* <Col md={6} lg={4} className="text-end">
								<StaticImage
									src="../images/Google-Ads-Icon.svg"
									placeholder="blurred"
									quality={100}
									className="w-100"
								/>
							</Col> */}
            </Row>
            <Row className="gx-xl-9 gy-5 justify-content-center">
              <Col className="position-relative" md={6} lg={4}>
                <div
                  style={{ top: "-7rem" }}
                  className="position-absolute text-center w-100 h-100"
                >
                  <img src={yellowOne} className="w-md-40 w-30" />
                </div>
                <div className="px-xl-5 pt-5 position-relative">
                  <h3 className="fs-1 text-white">Good for SEO</h3>
                  <p className="text-white">
                    Blogs can help to add meaningful content to your sales
                    landing pages ultimately boosting your SEO efforts to help
                    with your website's visibility on search engines.
                  </p>
                </div>
              </Col>
              <Col className="position-relative" md={6} lg={4}>
                <div
                  style={{ top: "-7rem" }}
                  className="position-absolute text-center w-100 h-100"
                >
                  <img src={yellowTwo} className="w-md-40 w-30" />
                </div>
                <div className="px-xl-5 pt-5 position-relative">
                  <h3 className="fs-1 text-white">Variety</h3>
                  <p className="text-white">
                    Adding different content to your blogs in a range of forms
                    such as infographics, videos, audio clips and links can make
                    your content more appealing to your users.
                  </p>
                </div>
              </Col>
              <Col className="position-relative " md={6} lg={4}>
                <div
                  style={{ top: "-7rem" }}
                  className="position-absolute w-100 text-center h-100"
                >
                  <img src={yellowThree} className="w-md-40 w-30" />
                </div>
                <div className="px-xl-5 pt-5 position-relative">
                  <h3 className="fs-1 text-white">Build trust</h3>
                  <p className="text-white">
                    By sharing industry news, updates and your expert opinions
                    can help to establish your business as a thought leader in
                    your market.
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        {/* <section id="results" className="bg-light-grey py-5 py-lg-10">
					<Container>
						<Row className=" align-items-center gx-6">
							<Col lg={5}>
								<div
									style={{
										overflow: "hidden",
										borderRadius: "15px",
										boxShadow: "0px 3px 99px #0000001D",
									}}
								>
									<GatsbyImage
										className="w-100"
										image={data.adsImage.gatsbyImage}
										alt={data.adsImage.altText}
									/>
								</div>
							</Col>
							<Col lg={7} className=" ">
								<h2 className="display-5 text-primary mb-5 mt-5 mt-lg-0">
									Expertly managed ad campaigns that deliver
								</h2>
								<p>
									We personalise every campaign to align with your business
									goals and ambitions. Whether you want a dramatic increase in
									traffic, lowered cost-per-lead, or make your existing ads more
									creative, we are here to help you. Our talented team will take
									care of the entire process and will constantly work with you
									to optimise each ad campaign.
								</p>
								<Button
									className=" w-100 cta-btn w-md-auto mt-4 fs-5 white-link-yellow py-2 px-4 "
									variant="primary"
									href="#form"
								>
									Talk to an expert
								</Button>
								<Button
									className=" w-100 cta-btn w-md-auto fs-5 ms-md-4  white-link-yellow py-2 px-4 mb-5 mb-md-0 mt-4 "
									variant="primary"
									as={Link}
									to="/contact-us"
								>
									Get in touch
								</Button>
								<Row className="pt-5 g-6">
									<Col lg={4}>
										<div className=" w-100 d-flex  align-items-center justify-content-center text-center">
											<GatsbyImage
												className="w-100"
												image={
													data.ninjaLogoImg.gatsbyImage
												}
												alt={data.ninjaLogoImg.altText}
											/>
										</div>
									</Col>
									<Col lg={4}>
										<div className=" w-100 d-flex  align-items-center justify-content-center text-center">
											<GatsbyImage
												className="w-100"
												image={
													data.weddingImg.gatsbyImage
												}
												alt={data.weddingImg.altText}
											/>
										</div>
									</Col>
								</Row>
							</Col>
						</Row>
					</Container>
				</section> */}
        {/* <section className="bg-primary py-5 py-lg-7">
					<Container>
						<Row className="justify-content-center">
							<Col lg={8} xl={6} className="text-center">
								<p className="ssp-bold text-uppercase text-light-blue">
									GOOGLE ADS
								</p>
								<h2 className="display-5 text-white mb-5 ">
									Types of ads we manage
								</h2>
							</Col>
						</Row>
						<Row className="mb-5 justify-content-lg-between justify-content-center gy-5">
							<Col md={6} lg={4} xl={3} className="text-md-center ">
								<BiSearch className="display-3 text-white" />
								<h3 className="ssp-bold text-white mb-4 mt-2 my-md-4">
									Search
								</h3>
								<p style={{ fontSize: "100%" }} className="text-white">
									Get your website to the top of the search results and only pay
									once a customer clicks on it.
								</p>
							</Col>
							<Col md={6} lg={4} xl={3} className="text-md-center ">
								<HiShoppingBag className="display-3 text-white" />
								<h3 className="ssp-bold text-white mb-4 mt-2 my-md-4">
									Shopping
								</h3>
								<p style={{ fontSize: "100%" }} className="text-white">
									Promote your E-commerce products with eye-catching visual ads
									on different search engines.
								</p>
							</Col>
							<Col md={6} lg={4} xl={3} className="text-md-center ">
								<AiFillGoogleCircle className="display-3 text-white" />
								<h3 className="ssp-bold text-white mb-4 mt-2 my-md-4">
									Google Network
								</h3>
								<p style={{ fontSize: "100%" }} className="text-white">
									Use Google owned websites such as Gmail, as well as Google
									approved third party sites to advertise your business.
								</p>
							</Col>
						</Row>
						<Row>
							<Col className="text-center">
								<Button
									className={`mt-4 mt-md-5 fs-5 w-100  w-md-auto cta-btn py-2 px-4 primary-link-dark `}
									variant="white"
									href="#form"
								>
									Talk to an expert
								</Button>
							</Col>
						</Row>
					</Container>
				</section> */}
        <WhyChooseRJMSection
          experienced="After many years of experience our specialist team knows how to write blogs that are valuable and insightful for your users to get your website more engagement."
          bespoke="Every blog we create will be personalised and written for your specific business, ensuring that it is targeted towards your ideal customers."
          provenResults="Since we began, 5 years ago, we have continually exceeded the results that our customers have wanted to see. Whether it be better ranking in search results through blogs optimised for SEO or more engaging content for your customers, we will create your blogs to meet your goals."
          reliable="Our team is always on hand to answer and assist with any queries you may have regarding the creation and writing of the content on your blogs."
          transparent="Throughout our journey with you, we will keep you up to date with the progress of your blogs and will work with you to create and develop the content of your blogs."
          trustworthy="You can always count on us to be there for you along every step of the way. We will have regular meetings to ensure we are all ontrack to helping you achieve your goals."
        />{" "}
        <section className="py-5 py-lg-7  bg-light-grey">
          <Container>
            <Row id="form">
              <Col className="text-center">
                {" "}
                <h2 className="display-5 text-primary mb-3">
                  Talk to an expert
                </h2>
                <p>
                  Book a time and day that works for you through our online
                  calendar to discuss your blog creation project.
                </p>
              </Col>
            </Row>
            <Row>
              <Col className="d-xl-none">
                <InlineWidget
                  className="calender"
                  styles={{ minWidth: "100%", height: "1150px" }}
                  url="https://calendly.com/robbie-rjm-digital/rjm-discovery-meeting?hide_gdpr_banner=1"
                />
              </Col>
              <Col className="d-none d-xl-block">
                <InlineWidget
                  className="calender"
                  styles={{ minWidth: "100%", height: "700px" }}
                  url="https://calendly.com/robbie-rjm-digital/rjm-discovery-meeting?hide_gdpr_banner=1"
                />
              </Col>
            </Row>
          </Container>
        </section>
        {/* <div id="approach">
					<OASection
						buttonUrl="#form"
						buttonText="Talk to an expert"
						imgHeight="60rem"
						image={data.ninjaImg.gatsbyImage}
						imageAlt={data.ninjaImg.altText}
						heading4="REPORTING"
						launch="We send monthly reports on how well each campaign is performing as well as review meetings so we can discuss one on one what is working well and what needs improving."
						heading3="CAMPAIGN MANAGMENT"
						build="Over the course of the campaign we will review and optimise the Ad in order to get the most out of your budget. If we need to make any changes, we will be in contact with you to discuss any recommendations to make your campaign better."
						heading2="CREATING THE ADS"
						design="Once you're happy with the proposed campaigns,  we will begin creating the Ads to make the copy, images and other available features as eye-catching as possible."
						brief="We research your customers, to understand what they search for that relates to your business's services. We then discuss the proposed campaigns and the targeted audiences with you to ensure this matches your ideal customer profile."
					/>
				</div> */}
        {/* <div className="mt-5 mt-lg-7">
					<Logos />
				</div> */}
        {/* <section className="pt-5 pb-md-5 py-lg-7 bg-light-grey">
					<ReviewSection page="Google Ads" />
				</section>
				<div id="faq">
					<Faq page="Google Ads" />
				</div> */}
        {/* <OSSection
          link2="/web-design"
          text2="WEB DESIGN"
          link3="/training"
          text3="TRAINING"
          image={data.sideImg1.gatsbyImage}
          imageAlt={data.sideImg1.altText}
        /> */}
      </Layout>
    </>
  );
};

export default GoogleAdsPage;
