import { graphql, useStaticQuery } from "gatsby";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import React from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { Helmet } from "react-helmet";
import AuditForm from "../components/audit-form";
import Hero from "../components/hero";
import Layout from "../components/layout";
import OSSection from "../components/other-services-section";

const WebsiteEmissionsAuditPage = () => {
	const data = useStaticQuery(graphql`
		query {
			heroImg: wpMediaItem(title: { eq: "Hero-Homepage" }) {
				altText
				mediaDetails {
					height
					width
				}
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}
			sideImg1: wpMediaItem(title: { eq: "Google-image" }) {
				altText
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}

			wpSeoPage(title: { eq: "Website Emissions Audit" }) {
				contentForHeadTag {
					content
				}
				seo {
					metaDesc
					title
					twitterImage {
						altText
						mediaDetails {
							height
							width
						}
						link
						gatsbyImage(
							width: 1920
							quality: 75
							formats: [WEBP]
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
					opengraphTitle
					opengraphDescription
				}
			}
			icon: wpMediaItem(title: { eq: "icon" }) {
				link
			}

			site {
				siteMetadata {
					siteUrl
				}
			}
		}
	`);

	const siteUrl = data.site.siteMetadata.siteUrl;
	const schema = data.wpSeoPage.contentForHeadTag.content;

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: "2",
				name: "Website Emissions Audit",
				item: {
					url: `${siteUrl}/website-emissions-audit`,
					id: `${siteUrl}/website-emissions-audit`,
				},
			},
		],
	};

	return (
		<>
			<Helmet>
				{" "}
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>

			<GatsbySeo
				title={data.wpSeoPage.seo.title}
				description={data.wpSeoPage.seo.metaDesc}
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}/website-emissions-audit`,
					title: `${data.wpSeoPage.seo.opengraphTitle}`,
					description: `${data.wpSeoPage.seo.opengraphDescription}`,
					images: [
						{
							url: `${data.wpSeoPage.seo.twitterImage.link}`,
							width: `${data.wpSeoPage.seo.twitterImage.mediaDetails.width}`,
							height: `${data.wpSeoPage.seo.twitterImage.mediaDetails.height}`,
							alt: `${data.wpSeoPage.seo.twitterImage.altText}`,
						},
					],
				}}
			/>

			<Layout>
				<section>
					<Hero
						button1="white-link-yellow"
						button1Text="Submit my website"
						button1Link="#form"
						button1Var="primary"
						button2={null}
						button3={null}
						backgroundImage={data.heroImg.gatsbyImage}
						title={`WEBSITE \nEMISSIONS \n<span class="text-secondary">AUDIT</span>`}
						description="Complete the form and receive your website audit report within 48 hours."
					/>
				</section>

				<section className="py-5 py-lg-7">
					<Container>
						<Row id="form" className="justify-content-center">
							<Col className="text-center" lg={8} xl={6}>
								<p className="ssp-bold text-uppercase text-light-blue">
									WEBSITE EMISSIONS AUDIT
								</p>
								<h2 className="display-5 text-primary mb-4">
									Get your free audit now
								</h2>
								<p className="mb-4">
									Fill out the form below to receive your website emissions
									report within 48 hours. We will arrange a call/Zoom to discuss
									the output and our recommendations.
								</p>
								<AuditForm />
							</Col>
						</Row>
					</Container>
				</section>

				{/* <OSSection
					image={data.sideImg1.gatsbyImage}
					imageAlt={data.sideImg1?.altText}
				/> */}
			</Layout>
		</>
	);
};

export default WebsiteEmissionsAuditPage;
