import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, StaticImage } from "gatsby-plugin-image";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import React from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { InlineWidget } from "react-calendly";
import { Helmet } from "react-helmet";
import Hero from "../components/hero";
import Layout from "../components/layout";
import OSSection from "../components/other-services-section";
import OASection from "../components/our-approach-section";
import WhyChooseRJMSection from "../components/why-choose-rjm-section";
import yellowOne from "../images/1-yellow.svg";
import yellowTwo from "../images/2-yellow.svg";
import yellowThree from "../images/3-yellow.svg";

const WebDesignPage = () => {
	const data = useStaticQuery(graphql`
		query {
			heroImg: wpMediaItem(title: { eq: "Hero-Homepage" }) {
				altText
				mediaDetails {
					height
					width
				}
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}
			sideImg1: wpMediaItem(title: { eq: "Google-image" }) {
				altText
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}
			img1: wpMediaItem(title: { eq: "Landing pages landing page" }) {
				altText
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}
			capitalImg: wpMediaItem(title: { eq: "capitaltocoast-fullpage" }) {
				altText
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}
			hiveImg: wpMediaItem(title: { eq: "Hivehr-fullpage" }) {
				altText
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}
			googleAImg: wpMediaItem(title: { eq: "google-analytics-new" }) {
				altText
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}
			weddingImg: wpMediaItem(title: { eq: "webdesign-laptop" }) {
				altText
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}
			googleSearchImg: wpMediaItem(title: { eq: "google-search" }) {
				altText
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}
			wpSeoPage(title: { eq: "Landing Pages" }) {
				contentForHeadTag {
					content
				}
				seo {
					metaDesc
					title
					twitterImage {
						altText
						mediaDetails {
							height
							width
						}
						link
						gatsbyImage(
							width: 1920
							quality: 75
							formats: [WEBP]
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
					opengraphTitle
					opengraphDescription
				}
			}
			icon: wpMediaItem(title: { eq: "icon" }) {
				link
			}
			site {
				siteMetadata {
					siteUrl
				}
			}
		}
	`);

	const siteUrl = data.site.siteMetadata.siteUrl;
	const schema = data.wpSeoPage.contentForHeadTag.content;

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: "2",
				name: "Landing Pages",
				item: {
					url: `${siteUrl}/landing-pages`,
				},
			},
		],
	};

	return (
		<>
			<Helmet>
				{" "}
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>
			<Helmet>
				{" "}
				<script type="application/ld+json">{schema}</script>
			</Helmet>
			{/* <LocalBusinessSchema /> */}
			<GatsbySeo
				title={data.wpSeoPage.seo.title}
				description={data.wpSeoPage.seo.metaDesc}
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}/landing-pages`,
					title: `${data.wpSeoPage.seo.opengraphTitle}`,
					description: `${data.wpSeoPage.seo.opengraphDescription}`,
					images: [
						{
							url: `${data.wpSeoPage.seo.twitterImage.link}`,
							width: `${data.wpSeoPage.seo.twitterImage.mediaDetails.width}`,
							height: `${data.wpSeoPage.seo.twitterImage.mediaDetails.height}`,
							alt: `${data.wpSeoPage.seo.twitterImage.altText}`,
						},
					],
				}}
			/>

			<Layout>
				<section>
					<Hero
						button1="primary-link-dark"
						button1Text="Talk to an expert"
						button1Link="#form"
						button1Var="white"
						// button2="white-link-yellow"
						// button2Text="Pricing"
						// button2Link="#pricing"
						// button2Var="primary"
						button2={null}
						button3={null}
						backgroundImage={data.heroImg.gatsbyImage}
						title={`Landing \n<span class="text-secondary">pages</span>`}
						description="Building unique and effective landing pages to drive conversions."
					/>
				</section>
				{/* <Logos /> */}
				{/* <section className="py-5 py-lg-7 bg-light-grey d-none d-lg-block">
					<Container className="p-5 bg-white">
						<Row className="justify-content-center gy-4">
							<Col className="text-center" md={4} lg={true}>
								<a
									className="text-primary ssp-regular fs-5 border-top-yellow"
									href="#intro"
								>
									Introduction
								</a>
							</Col>
							<Col className="text-center" md={4} lg={true}>
								<a
									className="text-primary ssp-regular fs-5 border-top-yellow"
									href="#results"
								>
									Delivering results
								</a>
							</Col>
							<Col className="text-center" md={4} lg={true}>
								<a
									className="text-primary ssp-regular fs-5 border-top-yellow"
									href="#pricing"
								>
									Pricing
								</a>
							</Col>
							<Col className="text-center" md={4} lg={true}>
								<a
									className="text-primary ssp-regular fs-5 border-top-yellow"
									href="#approach"
								>
									Our approach
								</a>
							</Col>
							<Col className="text-center" md={4} lg={true}>
								<a
									className="text-primary ssp-regular fs-5 border-top-yellow"
									href="#faq"
								>
									FAQ's
								</a>
							</Col>
						</Row>
					</Container>
				</section> */}
				<section id="intro" className="bg-light-grey py-5 py-lg-7">
					<Container>
						<Row className="align-items-center gx-lg-6 gx-xl-7">
							<Col lg={6} className=" mb-5 mb-lg-0 ">
								<h2 className="display-5 text-primary mb-5">
									Creating landing pages designed to convert more
								</h2>
								<div
									className="d-lg-none mb-4"
									style={{ overflow: "hidden", borderRadius: "15px" }}
								>
									<GatsbyImage
										className="w-100"
										image={data.img1.gatsbyImage}
										alt={data.img1?.altText}
									/>
								</div>
								<p>
									Building landing pages for particular products or services is
									one of the most effective ways to direct your customers to
									take a specific action. Whether your aim is for your users to
									make an inquiry, book a meeting or request more information,
									you can achieve this through building successful landing
									pages. With the support of RJM Digital, you can build landing
									pages that generate new leads and drive sales like never
									before.
								</p>
								<p>
									Why should you build landing pages? The aim with your
									different landing pages is to convert users into leads and
									customers, therefore making your message clear and concise is
									crucial. Your pages should be designed to remove any
									distractions by keeping all content focused on the particular
									service or product and guide your customers to your desired
									action.{" "}
								</p>

								<Button
									className=" cta-btn w-100 d-none d-xl-inline-block w-md-auto mt-4 me-md-4 fs-5 white-link-yellow py-2 px-4 "
									variant="primary"
									href="#form"
								>
									Talk to an expert today
								</Button>
								{/* <Button
									className=" cta-btn w-100 w-md-auto mt-4 fs-5 white-link-yellow py-2 px-4 "
									variant="primary"
									href="#pricing"
								>
									Website pricing
								</Button> */}
							</Col>
							<Col className="d-none d-lg-block" lg={6}>
								<div style={{ overflow: "hidden", borderRadius: "15px" }}>
									<GatsbyImage
										className="w-100"
										image={data.img1.gatsbyImage}
										alt={data.img1?.altText}
									/>
								</div>
							</Col>
						</Row>
					</Container>
				</section>
				<section id="benefits" className="py-5 py-lg-7 bg-dark-grey">
					<Container className="mb-8">
						<Row className="justify-content-between align-items-center">
							<Col md={9} lg={8}>
								<h2 className="display-2 text-uppercase ssp-bold text-white mb-lg-5">
									3 benefits of
									<span>
										<br /> <span className="text-secondary">landing pages</span>
									</span>
								</h2>
							</Col>
							{/* <Col md={6} lg={4} className="text-end">
								<StaticImage
									src="../../images/Google-Ads-Icon.svg"
									placeholder="blurred"
									quality={100}
									className="w-100"
								/>
							</Col> */}
						</Row>
						<Row className="gx-xl-9 gy-5 justify-content-center">
							<Col className="position-relative" md={6} lg={4}>
								<div
									style={{ top: "-7rem" }}
									className="position-absolute text-center w-100 h-100"
								>
									<image
										src={yellowOne}
									
										className="w-md-40 w-30"
									/>
								</div>
								<div className="px-xl-5 pt-5 position-relative">
									<h3 className="fs-1 text-white">Take action</h3>
									<p className="text-white">
										By creating landing pages with call to action buttons, you
										can help to direct your customers to take a particular
										action, such as booking a meeting or filling out a contact
										form.
									</p>
								</div>
							</Col>
							<Col className="position-relative" md={6} lg={4}>
								<div
									style={{ top: "-7rem" }}
									className="position-absolute text-center w-100 h-100"
								>
									<image
										src={yellowTwo}
									
										className="w-md-40 w-30"
									/>
								</div>
								<div className="px-xl-5 pt-5 position-relative">
									<h3 className="fs-1 text-white">Lead generation</h3>
									<p className="text-white">
										Incorporating valuable resources onto your landing pages
										such as a free audit or an ebook, in exchange of your
										customers details, will allow you to build an email list for
										any future marketing ideas.
									</p>
								</div>
							</Col>
							<Col className="position-relative " md={6} lg={4}>
								<div
									style={{ top: "-7rem" }}
									className="position-absolute w-100 text-center h-100"
								>
										<image
										src={yellowThree}
									
										className="w-md-40 w-30"
									/>
								</div>
								<div className="px-xl-5 pt-5 position-relative">
									<h3 className="fs-1 text-white">Specific</h3>
									<p className="text-white">
										Building landing pages for individual service locations or
										products can give your customers the exact information they
										need for what they are looking for.{" "}
									</p>
								</div>
							</Col>
						</Row>
					</Container>
				</section>
				<section id="intro" className="bg-light-grey py-5 py-lg-7">
					<Container>
						<Row className="align-items-center gx-lg-6 gx-xl-7">
							<Col lg={6} className=" mb-5 mb-lg-0 ">
								<h2 className="display-5 text-primary mb-5 mt-5 mt-lg-0">
									So, what is the difference?
								</h2>
								<div
									className="d-lg-none mb-4"
									style={{ overflow: "hidden", borderRadius: "15px" }}
								>
									<GatsbyImage
										className="w-100"
										image={data.weddingImg.gatsbyImage}
										alt={data.weddingImg?.altText}
									/>
								</div>

								<h3 className="text-primary">SEO pages</h3>
								<p>
									These are regular website pages that are optimised to rank
									high in search engine results pages for specific keywords.
									Their design is the same as the rest of the website including
									key elements such as the header and the footer. Typically they
									cover a broad subject in lots of detail with a variety of
									images and links.
								</p>
								<h3 className="text-primary mt-4">Landing pages</h3>
								<p>
									These pages are separate web pages created specifically for
									marketing or advertising campaigns. Their goal is to convert
									visitors into leads or customers by directing them to take a
									specific action. They usually have a more simple layout to
									ensure the user is focused solely on the call-to-action.
								</p>
							</Col>
							<Col className="d-none d-lg-block" lg={6}>
								<div style={{ overflow: "hidden", borderRadius: "15px" }}>
									<GatsbyImage
										className="w-100"
										image={data.weddingImg.gatsbyImage}
										alt={data.weddingImg?.altText}
									/>
								</div>
							</Col>
						</Row>
					</Container>
				</section>
				{/* <div id="pricing">
					<PricingSection btnText="Talk to an expert" btnLink="#form" />
				</div> */}
				<div id="approach">
					<OASection
						buttonUrl="#form"
						buttonText="Talk to an expert"
						imgHeight="60rem"
						image={data.hiveImg.gatsbyImage}
						imageAlt={data.hiveImg?.altText}
						launch="After conducting thorough checks to ensure your landing pages are functioning flawlessly and the content is worded perfectly, we are prepared to launch. Our dedicated support team will take charge of maintaining and overseeing your website, and are available to assist you with any changes or updates you may require."
						build="By using the latest website technologies, we build landing pages with super-fast loading speed, responsiveness across all devices, and integration with any CRM allowing you to track your leads. We will then test your landing pages on multiple devices to ensure they are compatible on any screen size and are optimised for SEO to be found on Google."
						design="Once we have a clear understanding of your requirements, we will begin the design process for your landing pages, ensuring all call-to-actions are clear and the content is purposeful. Throughout each phase, we will present our progress to you, allowing you to provide feedback and make any necessary adjustments until you are fully satisfied with the final result."
						brief="RJM Digital will make the process of building landing pages become effortless. We will take time to understand your marketing campaign including the ads you want to promote and the actions you want your customers to take, before our experienced design team begins to create your landing pages."
					/>
				</div>
				<WhyChooseRJMSection
					experienced="Since being in this industry for over 5 years, our specialist team have the experience and knowledge of building successful landing pages that help to convert potential customers and drive sales."
					bespoke="Each and every landing page we build is unique to your business, with the product or service you offer in the forefront of our mind. Ensuring that your landing pages are concise and purposeful, providing your customers with a clear message."
					provenResults="RJM Digital was founded back in 2018, and since then we have always exceeded our customers expectations. Demonstrating the importance of landing pages with results that they have found themselves such as more leads being generated and highlighting how useful they can be as a marketing tool by ranking high on search engine results pages."
					reliable="Our dedicated team is on hand to answer and assist you with any queries you may have regarding the creation and design of your landing pages, along with any necessary updates."
					transparent="We will work with you and keep you up-to-date throughout the entire process of designing, building, and launching your new landing pages. Our support team will always be there for you, should you need any extra assistance."
					trustworthy="You know RJM Digital will always be there for you. We will have regular meetings to discuss the progress of your landing pages and any other details that may need addressing, to ensure we are all on track to help you achieve more from your website. You can count on us."
				/>{" "}
				<section className="pt-5 pt-lg-7  bg-light-grey">
					<Container>
						<Row id="form">
							<Col className="text-center">
								{" "}
								<h2 className="display-5 text-primary mb-3">
									Talk to an expert
								</h2>
								<p>
									Book a time and day that works for you through our online
									calendar to discuss your landing pages.
								</p>
							</Col>
						</Row>
						<Row>
							<Col className="d-xl-none">
								<InlineWidget
									className="calender"
									styles={{ minWidth: "100%", height: "1150px" }}
									url="https://calendly.com/robbie-rjm-digital/rjm-discovery-meeting?hide_gdpr_banner=1"
								/>
							</Col>
							<Col className="d-none d-xl-block">
								<InlineWidget
									className="calender"
									styles={{ minWidth: "100%", height: "700px" }}
									url="https://calendly.com/robbie-rjm-digital/rjm-discovery-meeting?hide_gdpr_banner=1"
								/>
							</Col>
						</Row>
					</Container>
				</section>
				{/* <section className="pt-5 pb-md-5 py-lg-7 bg-light-grey">
					<ReviewSection page="Our Web Services" />
				</section>
				<div id="faq">
					<Faq page="Web Design" />
				</div> */}
				{/* <OSSection
					link3="/sustainable-web-design"
					text3="SUSTAINABLE WEBSITES"
					image={data.sideImg1.gatsbyImage}
					imageAlt={data.sideImg1?.altText}
				/> */}
			</Layout>
		</>
	);
};

export default WebDesignPage;
