import { Link, graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, StaticImage } from "gatsby-plugin-image";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import React from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { InlineWidget } from "react-calendly";
import { Helmet } from "react-helmet";
import Faq from "../components/faq";
import Layout from "../components/layout";
import OSSection from "../components/other-services-section";
import SeoHero from "../components/seo-hero";
import SeoMapSection from "../components/seo-map-section";
import TeamSection from "../components/team-section";
import WhyChooseRJMSection from "../components/why-choose-rjm-section";
import blueOne from "../images/1-blue.svg";
import blueTwo from "../images/2-blue.svg";
import blueThree from "../images/3-blue.svg";

const BusinessCoachWebDesign = () => {
  const data = useStaticQuery(graphql`
    query {
      heroImg: wpMediaItem(title: { eq: "Hero-Homepage" }) {
        altText
        mediaDetails {
          height
          width
        }
        link
        gatsbyImage(
          width: 1920
          quality: 75
          formats: [WEBP]
          layout: CONSTRAINED
          placeholder: BLURRED
        )
      }
      sideImg1: wpMediaItem(title: { eq: "Google-image" }) {
        altText
        gatsbyImage(
          width: 1920
          quality: 75
          formats: [WEBP]
          layout: CONSTRAINED
          placeholder: BLURRED
        )
      }

      ninjaLogoImg: wpMediaItem(title: { eq: "ninjaarenalogo1" }) {
        altText
        link
        gatsbyImage(
          width: 1920
          quality: 75
          formats: [WEBP]
          layout: CONSTRAINED
          placeholder: BLURRED
        )
      }
      weddingImg: wpMediaItem(title: { eq: "weddingcomanylogo" }) {
        altText
        link
        gatsbyImage(
          width: 1920
          quality: 75
          formats: [WEBP]
          layout: CONSTRAINED
          placeholder: BLURRED
        )
      }

      seoImg: wpMediaItem(title: { eq: "SEARCH ENGINE-OPTIMISATION-IMAGE-2" }) {
        altText
        link
        gatsbyImage(
          width: 1920
          quality: 75
          formats: [WEBP]
          layout: CONSTRAINED
          placeholder: BLURRED
        )
      }
      needHelpImage: wpMediaItem(title: { eq: "Business Coach Image" }) {
        altText
        link
        gatsbyImage(
          width: 1920
          quality: 75
          formats: [WEBP]
          layout: CONSTRAINED
          placeholder: BLURRED
        )
      }
      adsImage: wpMediaItem(title: { eq: "ads-image" }) {
        altText
        link
        gatsbyImage(
          width: 1920
          quality: 75
          formats: [WEBP]
          layout: CONSTRAINED
          placeholder: BLURRED
        )
      }
      flexImage: wpMediaItem(title: { eq: "Flex MasterLogo" }) {
        altText
        link
        gatsbyImage(
          width: 1920
          quality: 75
          formats: [WEBP]
          layout: CONSTRAINED
          placeholder: BLURRED
        )
      }
      ryanImage: wpMediaItem(title: { eq: "Ryan-Dixon-Property-Logo-Master" }) {
        altText
        link
        gatsbyImage(
          width: 1920
          quality: 75
          formats: [WEBP]
          layout: CONSTRAINED
          placeholder: BLURRED
        )
      }
      wpSeoPage(title: { eq: "Business Coach Web Design" }) {
        contentForHeadTag {
          content
        }
        seo {
          metaDesc
          title
          twitterImage {
            altText
            mediaDetails {
              height
              width
            }
            link
            gatsbyImage(
              width: 1920
              quality: 75
              formats: [WEBP]
              layout: CONSTRAINED
              placeholder: BLURRED
            )
          }
          opengraphTitle
          opengraphDescription
        }
      }
      adsImageNew: wpMediaItem(title: { eq: "Business Coach Image 2" }) {
        altText
        link
        gatsbyImage(
          width: 1920
          quality: 75
          formats: [WEBP]
          layout: CONSTRAINED
          placeholder: BLURRED
        )
      }
      seymourImg: wpMediaItem(title: { eq: "Seymourslogo" }) {
        altText
        link
        gatsbyImage(
          width: 1920
          quality: 75
          formats: [WEBP]
          layout: CONSTRAINED
          placeholder: BLURRED
        )
      }
      jasonImg: wpMediaItem(title: { eq: "Jasoncorneslogo" }) {
        altText
        link
        gatsbyImage(
          width: 1920
          quality: 75
          formats: [WEBP]
          layout: CONSTRAINED
          placeholder: BLURRED
        )
      }
      icon: wpMediaItem(title: { eq: "icon" }) {
        link
      }

      site {
        siteMetadata {
          siteUrl
        }
      }
    }
  `);

  const siteUrl = data.site.siteMetadata.siteUrl;
  const schema = data.wpSeoPage.contentForHeadTag.content;

  const breadcrumb = {
    "@context": "http://schema.org",
    "@type": "BreadcrumbList",
    itemListElement: [
      {
        "@type": "ListItem",
        position: "1",
        name: "Home",
        item: {
          url: `${siteUrl}`,
          id: `${siteUrl}`,
        },
      },
      {
        "@type": "ListItem",
        position: "2",
        name: "Business Coach Web Design",
        item: {
          url: `${siteUrl}/business-coach-web-design`,
          id: `${siteUrl}/business-coach-web-design`,
        },
      },
    ],
  };

  return (
    <>
      <Helmet>
        {" "}
        <script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
      </Helmet>
      <Helmet>
        {" "}
        <script type="application/ld+json">{schema}</script>
      </Helmet>
      {/* <LocalBusinessSchema /> */}
      <GatsbySeo
        title={data.wpSeoPage.seo.title}
        description={data.wpSeoPage.seo.metaDesc}
        language="en"
        openGraph={{
          type: "website",
          url: `${siteUrl}/business-coach-web-design`,
          title: `${data.wpSeoPage.seo.opengraphTitle}`,
          description: `${data.wpSeoPage.seo.opengraphDescription}`,
          images: [
            {
              url: `${data.wpSeoPage.seo.twitterImage.link}`,
              width: `${data.wpSeoPage.seo.twitterImage.mediaDetails.width}`,
              height: `${data.wpSeoPage.seo.twitterImage.mediaDetails.height}`,
              alt: `${data.wpSeoPage.seo.twitterImage.altText}`,
            },
          ],
        }}
      />

      <Layout>
        <section>
          <SeoHero
            page="Business coach web design"
            form
            titleSize="display-1"
            title={`Business coach \n<span class="text-secondary">Web Design</span>`}
            description={
              <span>
                Helping you receive the results your business coach website
                demands
              </span>
            }
            buttons={[
              <Button
                className="px-4 py-2 bg-white border-white w-100 cta-btn w-md-auto mt-md-4 me-md-3 fs-5 blue-link-yellow"
                variant="primary"
                href="#form"
              >
                Talk to an expert
              </Button>,
              // <Button
              // 	className="px-5 py-2 mt-4 border-white w-100 cta-btn w-md-auto fs-5 white-link-yellow"
              // 	variant="primary"
              // 	href="#pricing"
              // >
              // 	Our pricing
              // </Button>,
            ]}
          />
        </section>
        {/* <section className="py-5 py-lg-7">
					<Container>
						<Row className="align-items-center justify-content-center g-5 g-lg-7">
							<Col xs={6} lg={4}>
								<a
									target="_blank"
									rel="noreferrer"
									href="https://www.flexofficesolutions.co.uk/"
								>
									<GatsbyImage
										image={
											data.flexImage.gatsbyImage
										}
										alt={data.flexImage.altText}
										class="w-100"
									/>
								</a>
							</Col>
							<Col xs={6} lg={4}>
								<GatsbyImage
									image={
										data.ryanImage.gatsbyImage
									}
									alt={data.ryanImage.altText}
									class="w-100"
								/>
							</Col>
						</Row>
					</Container>
				</section> */}
        {/* <section className="py-5 py-lg-7 bg-light-grey d-none d-lg-block">
					<Container className="p-5 bg-white">
						<Row className="justify-content-center gy-4">
							<Col className="text-center" md={4} lg={true}>
								<a
									className="text-primary ssp-regular fs-5 border-top-yellow"
									href="#intro"
								>
									Introduction
								</a>
							</Col>
							<Col className="text-center" md={4} lg={true}>
								<a
									className="text-primary ssp-regular fs-5 border-top-yellow"
									href="#benefits"
								>
									Benefits
								</a>
							</Col>
							<Col className="text-center" md={4} lg={true}>
								<a
									className="text-primary ssp-regular fs-5 border-top-yellow"
									href="#results"
								>
									Results achieved
								</a>
							</Col>
							<Col className="text-center" md={4} lg={true}>
								<a
									className="text-primary ssp-regular fs-5 border-top-yellow"
									href="#approach"
								>
									Our Approach
								</a>
							</Col>
							<Col className="text-center" md={4} lg={true}>
								<a
									className="text-primary ssp-regular fs-5 border-top-yellow"
									href="#faq"
								>
									FAQ's
								</a>
							</Col>
						</Row>
					</Container>
				</section> */}
        <section id="intro" className="py-5 bg-light-grey py-lg-7">
          <Container>
            <Row className="align-items-center justify-content-between gx-6">
              <Col lg={6} className="">
                <h2 className="mb-5 display-5 text-primary">
                  Get the most out of your business coach website
                </h2>

                <p>Does your website attract as many leads as you wished?</p>
                <p>
                  Be proud of your website and watch it grow with professional
                  web design by RJM Digital. Ensure your website is designed
                  with your business coach company at its core, in order to
                  attract more of your ideal customers.{" "}
                </p>
                <p>
                  Our proven web design solution will ensure your website
                  reflects the look and feel of your business perfectly. With a
                  unique design that is both user-friendly and attention
                  grabbing, your website will serve you better as a powerful
                  marketing tool, ensuring you receive more leads of higher
                  quality.
                </p>
                <p>
                  Get in touch today to see how RJM Digital can help you achieve
                  more through your web design.
                </p>

                <Button
                  className="px-4 py-2 mt-4 w-100 cta-btn w-md-auto fs-5 white-link-yellow"
                  variant="primary"
                  href="#form"
                >
                  Talk to an expert
                </Button>
              </Col>
              <Col lg={5}>
                <div
                  className="mt-5 mt-lg-0"
                  style={{
                    overflow: "hidden",
                    borderRadius: "15px",
                    boxShadow: "0px 3px 99px #00000027",
                  }}
                >
                  <GatsbyImage
                    className="w-100 "
                    image={data.needHelpImage.gatsbyImage}
                    alt={data.needHelpImage.altText}
                  />
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        <section id="results" className="pb-5 bg-white py-lg-10">
          <Container>
            <Row className=" align-items-center gx-6">
              <Col className="d-none d-lg-block" lg={5}>
                <div
                  style={{
                    overflow: "hidden",
                    borderRadius: "15px",
                    boxShadow: "0px 3px 99px #0000001D",
                  }}
                >
                  <GatsbyImage
                    className="w-100"
                    image={data.adsImageNew.gatsbyImage}
                    alt={data.adsImageNew.altText}
                  />
                </div>
              </Col>
              <Col lg={7} className="">
                <h2 className="mt-5 mb-5 display-5 text-primary mt-lg-0">
                  Finding you aren't receiving as many leads as you'd hoped?
                </h2>
                <p>
                  Are you dropping down the rankings on Google and don’t know
                  how to recover?
                </p>
                <p>
                  Our web design solution partnered with our SEO services are
                  the perfect combination to help you rank higher in the search
                  engine results pages. Reaching the number one spot on Google
                  can both increase your number of leads and bring your business
                  coach company greater recognition within the business coach
                  market.
                </p>
                <p>
                  SEO (Search Engine Optimisation) is the process of using
                  keywords relating to your industry to help you climb the
                  rankings of search engines, to get the top position on the
                  results pages organically.
                </p>
                <p>
                  Utilise this cost-effective method and get in touch today
                  about our SEO services.
                </p>
                <Button
                  className="px-4 py-2 mt-4 w-100 cta-btn w-md-auto fs-5 white-link-yellow"
                  variant="primary"
                  href="#form"
                >
                  Talk to an expert
                </Button>
                <Button
                  className="px-4 py-2 mt-4 mb-5 w-100 cta-btn w-md-auto fs-5 ms-md-4 white-link-yellow mb-md-0"
                  variant="primary"
                  as={Link}
                  to="/contact-us"
                >
                  Get in touch
                </Button>
                {/* <Row className="pt-5 g-6">
									<Col lg={4}>
										<div className="text-center w-100 d-flex align-items-center justify-content-center">
											<GatsbyImage
												className="w-100"
												image={
													data.ninjaLogoImg.gatsbyImage
												}
												alt={data.ninjaLogoImg.altText}
											/>
										</div>
									</Col>
									<Col lg={4}>
										<div className="text-center w-100 d-flex align-items-center justify-content-center">
											<GatsbyImage
												className="w-100"
												image={
													data.weddingImg.gatsbyImage
												}
												alt={data.weddingImg.altText}
											/>
										</div>
									</Col>
								</Row> */}
              </Col>
            </Row>
          </Container>
        </section>
        <section id="benefits" className="py-5 py-lg-7 bg-dark-grey">
          <Container className="">
            <Row>
              <Col>
                <h2 className="mb-5 text-white display-2 ssp-bold text-uppercase">
                  Advantages of a
                  <span>
                    <br />{" "}
                    <span className="text-light-blue">static website</span>
                  </span>
                </h2>
              </Col>
            </Row>
            <Row className="gx-xl-9 g-6 justify-content-center">
              <Col className="position-relative" md={6} lg={4}>
                <div
                  style={{ top: "-7rem" }}
                  className="text-center position-absolute w-100 h-100"
                >
                  <img src={blueOne} className="w-md-40 w-30" />
                </div>
                <div className="pt-5 px-xl-5 position-relative">
                  <h3 className="text-white fs-1">Ultra-fast</h3>
                  <p className="text-white">
                    With super fast load speeds, you can give your customers a
                    great user experience when on your site, allowing them to
                    find what they need quickly and with ease.
                  </p>
                </div>
              </Col>
              <Col className="position-relative" md={6} lg={4}>
                <div
                  style={{ top: "-7rem", zIndex: 0 }}
                  className="text-center position-absolute w-100 h-100"
                >
                  <img src={blueTwo} className="w-md-40 w-30" />
                </div>
                <div className="pt-5 px-xl-5 position-relative">
                  <h3 className="text-white fs-1">Easy to scale</h3>
                  <p className="text-white">
                    Grow your website as your business coach company grows as
                    static websites allow for efficient scalability.
                  </p>
                </div>
              </Col>
              <Col className="position-relative " md={6} lg={4}>
                <div
                  style={{ top: "-7rem" }}
                  className="text-center position-absolute w-100 h-100"
                >
                  <img src={blueThree} className="w-md-40 w-30" />
                </div>
                <div className="pt-5 px-xl-5 position-relative">
                  <h3 className="text-white fs-1">Best for SEO</h3>
                  <p className="text-white">
                    Search engines such as google rank websites that are easy to
                    use and user-friendly, so ensuring your website is designed
                    with the user in mind, you will rank well.
                  </p>
                </div>
              </Col>
            </Row>
            <Row>
              <Col className="text-center">
                <Button
                  className={`mt-5 mt-md-7 fs-5 w-100  w-md-auto cta-btn py-2 px-4 primary-link-dark `}
                  variant="white"
                  href="#form"
                >
                  Talk to an expert
                </Button>
              </Col>
            </Row>
          </Container>
        </section>
        <section id="results" className="pb-5 bg-light-grey pb-lg-7">
          <Container>
            <Row className="py-5 align-items-center py-xl-0">
              <Col lg={{ span: 8, order: "last" }}>
                <div style={{ overflow: "hidden", borderRadius: "15px" }}>
                  <GatsbyImage
                    className="w-100"
                    image={data.seoImg.gatsbyImage}
                    alt={data.seoImg.altText}
                  />
                </div>
              </Col>
              <Col lg={4} className=" mt-lg-0">
                <h2 className="mb-4 display-5 text-primary">
                  Our proven results
                </h2>
                <p>
                  Unleash the power of your business coach company with our web
                  design services.
                </p>
                <p>
                  Regardless of whether you want to reach the top of Google or
                  to increase your number of leads significantly. RJM Digital is
                  here to help you turn your business dreams into a reality with
                  our professional web design solution.
                </p>
                <Button
                  className="px-4 py-2 mt-4 cta-btn w-100 w-md-auto fs-5 white-link-yellow"
                  variant="primary"
                  href="#form"
                >
                  Talk to an expert
                </Button>
              </Col>
            </Row>

            <Row className="py-5 align-items-center our-results mt-md-5 py-lg-0 gy-5 gy-lg-0">
              <Col className="px-4 text-center py-md-4" xs={12} lg={true}>
                <div className="p-4 bg-jason">
                  <GatsbyImage
                    className="mw-100 "
                    image={data.jasonImg.gatsbyImage}
                    alt={data.jasonImg.altText}
                  />
                </div>
              </Col>
              <Col lg={true}>
                <h2 className="text-center ssp-bold text-lg-start fs-3">
                  Our results:
                </h2>
              </Col>
              <Col className="text-center" lg={true}>
                <p className="pb-1 mb-0 ssp-bold text-primary display-4">3</p>
                <h3 className="fs-5">Month ROI</h3>
              </Col>
              <Col className="text-center" lg={true}>
                <p className="pb-1 mb-0 ssp-bold text-primary display-4">
                  200%
                </p>
                <h3 className="fs-5">Increase traffic</h3>
              </Col>
              <Col className="text-center" lg={true}>
                <p className="pb-1 mb-0 ssp-bold text-primary display-4">5x</p>
                <h3 className="fs-5">More enquiries</h3>
              </Col>
            </Row>
          </Container>
        </section>

        {/* <section
					style={{
						background:
							"transparent linear-gradient(49deg, #023A7F 0%, #40B4E5 100%) 0% 0% no-repeat padding-box",
					}}
					className="py-5 py-lg-7"
				>
					<Container>
						<Row>
							<Col>
								<h2 className="mb-5 text-center text-white display-5 mb-lg-7">
									Key features of our real estate websites
								</h2>
							</Col>
						</Row>
						<Row className="g-5">
							<Col md={6} lg={4} xl={3} className="text-md-center ">
								<FaListUl className="text-white display-3" />
								<h3 className="my-4 text-white ssp-bold">Shortlist</h3>
								<p style={{ fontSize: "100%" }} className="text-white">
									Allow your prospects to search through your listings and pick
									which properties they want to view.
								</p>
							</Col>
							<Col md={6} lg={4} xl={3} className="text-md-center ">
								<FaFileImport className="text-white display-3" />
								<h3 className="my-4 text-white ssp-bold">Import listings</h3>
								<p style={{ fontSize: "100%" }} className="text-white">
									Import your listings easily via a spreadsheet and publish
									directly to your website.
								</p>
							</Col>
							<Col md={6} lg={4} xl={3} className="text-md-center ">
								<IoMdPaper className="text-white display-3" />
								<h3 className="my-4 text-white ssp-bold">
									Project Landing Pages
								</h3>
								<p style={{ fontSize: "100%" }} className="text-white">
									Add your knowledge and expertise to existing and upcoming
									property developments across London.
								</p>
							</Col>
							<Col md={6} lg={4} xl={3} className="text-md-center ">
								<FaLanguage className="text-white display-3" />
								<h3 className="my-4 text-white ssp-bold">Multiple languages</h3>
								<p style={{ fontSize: "100%" }} className="text-white">
									Help build trust and customer engagement by using their
									preferred language on your website.
								</p>
							</Col>
							<Col md={6} lg={4} xl={3} className="text-md-center ">
								<MdBrush className="text-white display-3" />
								<h3 className="my-4 text-white ssp-bold">Bespoke Design</h3>
								<p style={{ fontSize: "100%" }} className="text-white">
									We help to build a website that represents your brand and
									reflects who you are as an agency.
								</p>
							</Col>
							<Col md={6} lg={4} xl={3} className="text-md-center ">
								<IoIosSpeedometer className="text-white display-3" />
								<h3 className="my-4 text-white ssp-bold">Ultra-Fast</h3>
								<p style={{ fontSize: "100%" }} className="text-white">
									We will ensure a brilliant user experience by loading each
									web page in less than 1 second.
								</p>
							</Col>
							<Col md={6} lg={4} xl={3} className="text-md-center ">
								<FaGoogle className="text-white display-3" />
								<h3 className="my-4 text-white ssp-bold">Built for SEO</h3>
								<p style={{ fontSize: "100%" }} className="text-white">
									Appear higher on Google when buyers are searching for
									particular developments or projects.
								</p>
							</Col>
							<Col md={6} lg={4} xl={3} className="text-md-center ">
								<IoIosHelpBuoy className="text-white display-3" />
								<h3 className="my-4 text-white ssp-bold">Fully Managed</h3>
								<p style={{ fontSize: "100%" }} className="text-white">
									We will maintain, support and secure your website so you never
									need to worry.
								</p>
							</Col>
							<Col md={6} lg={4} xl={3} className="text-md-center ">
								<FaAngellist className="text-white display-3" />
								<h3 className="my-4 text-white ssp-bold">Featured Listings</h3>
								<p style={{ fontSize: "100%" }} className="text-white">
									Highlight your featured projects, listings or developments to
									attract buyers attention.
								</p>
							</Col>
							<Col md={6} lg={4} xl={3} className="text-md-center ">
								<HiDocumentText className="text-white display-3" />
								<h3 className="my-4 text-white ssp-bold">Upload useful docs</h3>
								<p style={{ fontSize: "100%" }} className="text-white">
									Increase the engagement on your website  by uploading useful
									documents such as floor plans to each listing.
								</p>
							</Col>
							<Col md={6} lg={4} xl={3} className="text-md-center ">
								<MdOndemandVideo className="text-white display-3" />
								<h3 className="my-4 text-white ssp-bold">Virtual Tours</h3>
								<p style={{ fontSize: "100%" }} className="text-white">
									Upload virtual tours or YouTube videos to create a more
									immersive experience for your users.
								</p>
							</Col>
							<Col md={6} lg={4} xl={3} className="text-md-center ">
								<FaInstagram className="text-white display-3" />
								<h3 className="my-4 text-white ssp-bold">Sync your socials</h3>
								<p style={{ fontSize: "100%" }} className="text-white">
									Sync your social media channels and showcase your content
									across different platforms.
								</p>
							</Col>
						</Row>
						<Row className="mt-5 w-100 d-flex justify-content-center">
							<Button
								className="px-4 py-2 mt-4 bg-white border-white w-100 cta-btn w-md-auto fs-5 blue-link-yellow"
								variant="primary"
								href="#form"
							>
								Book a demo
							</Button>
						</Row>
					</Container>
				</section> */}
        {/* <div id="pricing">
					<PricingSection btnText="Talk to an expert" btnLink="#form" />
				</div> */}
        <WhyChooseRJMSection
          experienced="To bring your business coach website the number and quality of leads you demand, you need to invest in your web design. We have over 5 years experience in the industry and have a proven platform for business coaches."
          bespoke="All the websites we create at RJM Digital are bespoke to the customer. We know that standing out from the crowd as a business coach is vital, so a unique design will help customers differentiate you in the market."
          provenResults="At RJM Digital we continually deliver the best results for our customers and our reviews showcase this. Many marketing agencies say they can deliver results but dont have the evidence to support their claims."
          reliable="RJM Digital will always be by your side for any help or assistance, no matter how big or small a job, we are always happy to lend a hand."
          transparent="Throughout our journey together, we will always be open in everything that we do, even when things may not go exactly as planned. Our friendly support team will always be available for a meeting or to answer any queries you may have."
          trustworthy="We follow our core values of being reliable, transparent and results-driven every day and we are proud to have built long-lasting relationships with over 50 customers around the globe."
        />

        <section className="pt-5 pt-lg-7 pb-xl-7 bg-light-grey">
          <Container>
            <Row id="form">
              <Col className="text-center">
                {" "}
                <h2 className="mb-3 display-5 text-primary">
                  Talk to an expert
                </h2>
                <p>
                  Book a time and day that works for you through our online
                  calendar to discuss your London estate agent web design
                  project.
                </p>
              </Col>
            </Row>
            <Row>
              <Col className="d-xl-none">
                <InlineWidget
                  className="calender"
                  styles={{ minWidth: "100%", height: "1150px" }}
                  url="https://calendly.com/robbie-rjm-digital/rjm-discovery-meeting?hide_gdpr_banner=1"
                />
              </Col>
              <Col className="d-none d-xl-block">
                <InlineWidget
                  className="calender"
                  styles={{ minWidth: "100%", height: "700px" }}
                  url="https://calendly.com/robbie-rjm-digital/rjm-discovery-meeting?hide_gdpr_banner=1"
                />
              </Col>
            </Row>
          </Container>
        </section>

        <SeoMapSection mapUrl="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d9928.942998834771!2d-0.0886605!3d51.5272357!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xd6972f4fcb15756d!2sRJM%20Digital!5e0!3m2!1sen!2sae!4v1673980072743!5m2!1sen!2sae" />
        {/* <section className="my-6">
          <TeamSection />
        </section> */}
        <div id="faq">
          <Faq page="Real Estate Dubai" />
        </div>
        {/* <OSSection
          image={data.sideImg1.gatsbyImage}
          imageAlt={data.sideImg1.altText}
        /> */}
      </Layout>
    </>
  );
};

export default BusinessCoachWebDesign;
