import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, StaticImage } from "gatsby-plugin-image";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import React from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { InlineWidget } from "react-calendly";
import { Helmet } from "react-helmet";
import Faq from "../components/faq";
import Hero from "../components/hero";
import Layout from "../components/layout";
import OSSection from "../components/other-services-section";

const SeoAuditEastbournePage = () => {
	const data = useStaticQuery(graphql`
		query {
			heroImg: wpMediaItem(title: { eq: "Hero-Homepage" }) {
				altText
				mediaDetails {
					height
					width
				}
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}
			sideImg1: wpMediaItem(title: { eq: "Google-image" }) {
				altText
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}
			icon: wpMediaItem(title: { eq: "icon" }) {
				link
			}
			wpSeoPage(title: { eq: "SEO Audit Eastbourne" }) {
				contentForHeadTag {
					content
				}
				seo {
					metaDesc
					title
					twitterImage {
						altText
						mediaDetails {
							height
							width
						}
						link
						gatsbyImage(
							width: 1920
							quality: 75
							formats: [WEBP]
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
					opengraphTitle
					opengraphDescription
				}
			}

			jasonImg: wpMediaItem(title: { eq: "ecf-long" }) {
				altText
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}
			seoImg: wpMediaItem(title: { eq: "seoaudit" }) {
				altText
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}
			seoTopImg: wpMediaItem(title: { eq: "Demystifying SEO presentation" }) {
				altText
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}

			site {
				siteMetadata {
					siteUrl
				}
			}
		}
	`);

	const siteUrl = data.site.siteMetadata.siteUrl;
	const schema = data.wpSeoPage.contentForHeadTag.content;

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: "2",
				name: "SEO Audit Eastbourne",
				item: {
					url: `${siteUrl}/seo-audit-eastbourne`,
				},
			},
		],
	};

	return (
		<>
			<Helmet>
				{" "}
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>
			<Helmet>
				{" "}
				<script type="application/ld+json">{schema}</script>
			</Helmet>
			{/* <LocalBusinessSchema /> */}
			<GatsbySeo
				title={data.wpSeoPage.seo.title}
				description={data.wpSeoPage.seo.metaDesc}
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}/seo-audit-eastbourne`,
					title: `${data.wpSeoPage.seo.opengraphTitle}`,
					description: `${data.wpSeoPage.seo.opengraphDescription}`,
					images: [
						{
							url: `${data.wpSeoPage.seo.twitterImage.link}`,
							width: `${data.wpSeoPage.seo.twitterImage.mediaDetails.width}`,
							height: `${data.wpSeoPage.seo.twitterImage.mediaDetails.height}`,
							alt: `${data.wpSeoPage.seo.twitterImage.altText}`,
						},
					],
				}}
			/>

			<Layout>
				<section>
					<Hero
						button1="primary-link-dark"
						button1Text="Audit my website"
						button1Link="#book-now"
						button1Var="white"
						button2={null}
						button3={null}
						backgroundImage={data.heroImg.gatsbyImage}
						title={`SEO AUDIT \n<span class="text-secondary">EASTBOURNE</span>`}
						description="Gain insight on how your website ranks on Google in Eastbourne and what you can do to improve your website and get more enquiries."
					/>
				</section>
				{/* <section className="py-5 py-lg-7 bg-light-grey d-none d-lg-block">
					<Container className="p-5 bg-white">
						<Row className="justify-content-center gy-4">
							<Col className="text-center" md={4} lg={true}>
								<a
									className="text-primary ssp-regular fs-5 border-top-yellow"
									href="#intro"
								>
									Introduction
								</a>
							</Col>
							<Col className="text-center" md={4} lg={true}>
								<a
									className="text-primary ssp-regular fs-5 border-top-yellow"
									href="#what-it-covers"
								>
									What it covers
								</a>
							</Col>
							<Col className="text-center" md={4} lg={true}>
								<a
									className="text-primary ssp-regular fs-5 border-top-yellow"
									href="#book-now"
								>
									Book now
								</a>
							</Col>
							<Col className="text-center" md={4} lg={true}>
								<a
									className="text-primary ssp-regular fs-5 border-top-yellow"
									href="#faq"
								>
									FAQ's
								</a>
							</Col>
						</Row>
					</Container>
				</section> */}
				<section id="intro" className="bg-light-grey py-5 py-lg-7">
					<Container>
						<Row className="align-items-center gx-6 gx-lg-6 gx-xl-7">
							<Col lg={5} className=" ">
								<h2 className="display-5 text-primary mb-5">
									Why have an SEO audit?
								</h2>
								<p>
									An SEO audit can help you understand how where your business
									is appearing on Google when customers search for you in
									Eastbourne, increase traffic to your website and give you a
									benchmark so you know what SEO changes would make the most
									impact for your business.
								</p>
							</Col>
							<Col lg={7}>
								<div
									style={{
										overflow: "hidden",
										borderRadius: "15px",
										boxShadow: "0px 3px 99px #00000027",
									}}
								>
									<GatsbyImage
										className="w-100 "
										image={data.seoTopImg.gatsbyImage}
										alt={data.seoTopImg.altText}
									/>
								</div>
							</Col>
						</Row>
					</Container>
				</section>
				<section
					style={{
						background:
							"transparent linear-gradient(58deg, #023A7F 0%, #40B4E5 100%) 0% 0% no-repeat padding-box",
					}}
					className="py-5 py-lg-9 "
				>
					<Container>
						<Row className=" align-items-center">
							<Col lg={5} className="position-relative ps-6">
								<div
									style={{
										borderRadius: " 15px",
										boxShadow: "0px 3px 30px #00000029",
										height: "30rem",
										overflow: "hidden",
									}}
									className="bg-white me-auto   w-50"
								>
									<GatsbyImage
										className="w-100 "
										image={data.jasonImg.gatsbyImage}
										alt={data.jasonImg.altText}
										objectPosition="top center"
									/>
								</div>

								<div
									style={{
										borderRadius: " 15px",
										boxShadow: "0px 3px 30px #00000029",
										overflow: "hidden",
									}}
									className="bg-white position-absolute end-20 bottom-10   w-50"
								>
									<GatsbyImage
										className="w-100 "
										image={data.seoImg.gatsbyImage}
										alt={data.seoImg.altText}
										objectPosition="top center"
									/>
								</div>
							</Col>
							<Col lg={7}>
								<h2 className=" ssp-bold text-white mb-5 display-5 mt-5 mt-lg-0">
									Free SEO audit for Eastbourne businesses
								</h2>
								<p className="text-white">
									To receive your free SEO audit of the improvements you can
									make to your website, simply enter your URL and company
									details below. By completing this form you are agreeing for us
									to use your email address to send you a free SEO audit report
									and arranging a free consultation to run through our
									recommendations. Once you have received your report we can
									discuss the next steps to take to make sure your website is
									getting the results your website deserves.
								</p>
							</Col>
						</Row>
					</Container>
				</section>

				<section id="what-it-covers" className="py-5 py-lg-7 bg-light-grey">
					<Container>
						<Row>
							<Col>
								{" "}
								<h2 className="display-5 text-primary ssp-bold mb-7">
									Our comprehensive Free SEO audit report covers
								</h2>
							</Col>
						</Row>
						<Row className="g-lg-6 g-5">
							<Col md={6} lg={4}>
								<div
									className="d-flex align-items-end"
									style={{ height: "66px" }}
								>
									<StaticImage
										src="../images/SEO-audit-Icon-1.svg"
										placeholder="blurred"
										quality={100}
										style={{ maxWidth: "50px" }}
									/>
								</div>
								<h3 className=" ssp-bold text-primary my-3">
									Keyword Analysis
								</h3>
								<p>
									We will conduct in-depth keyword research, analysing how many
									searches are made to determine the best keywords for your
									website to target.
								</p>
							</Col>
							<Col md={6} lg={4}>
								<div
									className="d-flex align-items-end"
									style={{ height: "66px" }}
								>
									<StaticImage
										src="../images/SEO-audit-Icon-2.svg"
										placeholder="blurred"
										quality={100}
										style={{ maxWidth: "50px" }}
									/>
								</div>
								<h3 className=" ssp-bold text-primary my-3">Page speed</h3>
								<p>
									Many websites perform poorly due to slow load speed. With our
									technical SEO knowledge, we will work to improve the page
									speed across your site.
								</p>
							</Col>
							<Col md={6} lg={4}>
								<div
									className="d-flex align-items-end"
									style={{ height: "66px" }}
								>
									<StaticImage
										src="../images/SEO-audit-Icon-3.svg"
										placeholder="blurred"
										quality={100}
										style={{ maxWidth: "50px" }}
									/>
								</div>
								<h3 className=" ssp-bold text-primary my-3">
									Backlink analysis
								</h3>
								<p>
									Having a poor backlinks to your website can cause plenty of
									SEO issues, we’ll analyse links to your site, remove negative
									ones and discuss strategise for new ones.
								</p>
							</Col>
							<Col md={6} lg={4}>
								<div
									className="d-flex align-items-end"
									style={{ height: "66px" }}
								>
									<StaticImage
										src="../images/SEO-audit-Icon-4.svg"
										placeholder="blurred"
										quality={100}
										style={{ maxWidth: "50px" }}
									/>
								</div>
								<h3 className=" ssp-bold text-primary my-3">
									Competitor analysis
								</h3>
								<p>
									Understanding the digital marketing efforts of your
									competitors can help you improve your own strategy, we’ll
									review what your competitors are doing.
								</p>
							</Col>
							<Col md={6} lg={4}>
								<div
									className="d-flex align-items-end"
									style={{ height: "66px" }}
								>
									<StaticImage
										src="../images/SEO-audit-Icon-5.svg"
										placeholder="blurred"
										quality={100}
										style={{ maxWidth: "50px" }}
									/>
								</div>
								<h3 className=" ssp-bold text-primary my-3">Technical SEO</h3>
								<p>
									From setting up Google Search Console to writing schema markup
									for your webpages, a technical SEO audit can make a huge
									difference to the success of your site.
								</p>
							</Col>
							<Col md={6} lg={4}>
								<div
									className="d-flex align-items-end"
									style={{ height: "66px" }}
								>
									<StaticImage
										src="../images/SEO-audit-Icon-6.svg"
										placeholder="blurred"
										quality={100}
										style={{ maxWidth: "50px" }}
									/>
								</div>
								<h3 className=" ssp-bold text-primary my-3">
									Reporting & analytics
								</h3>
								<p>
									Our audit will see us set up Analytics to ensure you have
									clarity on your user acquisition and attribution modelling,
									helping SEO reporting and resource decisions.
								</p>
							</Col>
							<Col md={6} lg={4}>
								<div
									className="d-flex align-items-end"
									style={{ height: "66px" }}
								>
									<StaticImage
										src="../images/SEO-audit-Icon-7.svg"
										placeholder="blurred"
										quality={100}
										style={{ maxWidth: "50px" }}
									/>
								</div>
								<h3 className=" ssp-bold text-primary my-3">
									Information architecture
								</h3>
								<p>
									Knowing how to perfect the information architecture of your
									website is essential to achieve a positive user experience. We
									will submit any new XML sitemaps to Google.
								</p>
							</Col>
							<Col md={6} lg={4}>
								<div
									className="d-flex align-items-end"
									style={{ height: "66px" }}
								>
									<StaticImage
										src="../images/SEO-audit-Icon-8.svg"
										placeholder="blurred"
										quality={100}
										style={{ maxWidth: "50px" }}
									/>
								</div>
								<h3 className=" ssp-bold text-primary my-3">
									Content marketing strategy
								</h3>
								<p>
									Content is the backbone of SEO. We will work with you to
									optimise your current content and create a content marketing
									strategy that can be continued once your audit is complete.
								</p>
							</Col>
						</Row>
					</Container>
				</section>
				<section id="book-now" className="py-5 py-lg-7">
					<Container>
						<Row>
							<Col>
								{" "}
								<h2 className="display-5 text-primary mb-5">
									Book your free SEO audit now
								</h2>
								<p>
									Simply enter your details below and we will email you our
									report. Don't forget to book in a free consultation with one
									of our team, so we can talk through our recommendations for
									your business.
								</p>
							</Col>
						</Row>
						<Row>
							<Col className="d-xl-none">
								<InlineWidget
									className="calender"
									styles={{ minWidth: "100%", height: "1150px" }}
									url="https://calendly.com/robbie-rjm-digital/seo-audit?hide_gdpr_banner=1"
								/>
							</Col>
							<Col className="d-none d-xl-block">
								<InlineWidget
									className="calender"
									styles={{ minWidth: "100%", height: "700px" }}
									url="https://calendly.com/robbie-rjm-digital/seo-audit?hide_gdpr_banner=1"
								/>
							</Col>
						</Row>
					</Container>
				</section>

				<div id="faq">
					<Faq page="SEO Audit" />
				</div>
				{/* <OSSection
					link1="/seo-eastbourne"
					link3="/web-design-eastbourne"
					image={data.sideImg1.gatsbyImage}
					imageAlt={data.sideImg1.altText}
				/> */}
			</Layout>
		</>
	);
};

export default SeoAuditEastbournePage;
