import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Marquee from "react-fast-marquee";
import "react-multi-carousel/lib/styles.css";

const Logos = ({ noTitle, page }) => {
	const logos = useStaticQuery(graphql`
		query {
			allWpClientLogo {
				nodes {
					logoFields {
						url
						page

						image {
							gatsbyImage(
								width: 1920
								quality: 75
								formats: [WEBP]
								layout: CONSTRAINED
								placeholder: BLURRED
							)
						}
					}
				}
			}
		}
	`);

	const pageNew = page || "Home";

	const responsive = {
		desktop: {
			breakpoint: { max: 3000, min: 1024 },
			items: 6,
			slidesToSlide: 1, // optional, default to 1.
		},
		tablet: {
			breakpoint: { max: 1024, min: 464 },
			items: 4,
			slidesToSlide: 1, // optional, default to 1.
		},
		mobile: {
			breakpoint: { max: 464, min: 0 },
			items: 2,
			slidesToSlide: 1, // optional, default to 1.
		},
	};

	return (
		<section className="py-5 pt-xl-8 bg-light-grey">
			<Container className="d-none d-xl-block">
				<Row>
					<Col>
						{!noTitle && (
							<h2 className="text-primary display-5  text-center pb-5 pb-lg-7">
								OUR CUSTOMERS
															</h2>
						)}

						<Marquee speed="40" gradientColor={[253, 253, 253]}>
							{logos.allWpClientLogo.nodes
								.filter((item, index) => item.logoFields.page.includes(pageNew))
								.map((logo) => (
									<a
										target="_blank"
										rel="noreferrer"
										href={logo.logoFields.url}
									>
										<div className="ps-6 d-flex h-100 align-items-center">
											<GatsbyImage
												loading="eager"
												image={logo.logoFields.image.gatsbyImage}
												style={{
													maxWidth: "10rem",
												}}
											/>
										</div>
									</a>
								))}
						</Marquee>
					</Col>
				</Row>
				<Row className="d-lg-none">
					<Col></Col>
				</Row>
			</Container>
			<div className="d-xl-none">
				{!noTitle && (
					<h2 className="text-primary display-5  text-center pb-5 pb-lg-7">
						OUR CUSTOMERS
					</h2>
				)}
				<Marquee speed="40" gradient={false}>
					{logos.allWpClientLogo.nodes
						.filter((item, index) => item.logoFields.page.includes(pageNew))
						.map((logo) => (
							<a target="_blank" rel="noreferrer" href={logo.logoFields.url}>
								<div className="ps-6 d-flex h-100 align-items-center">
									<GatsbyImage
										loading="eager"
										image={logo.logoFields.image.gatsbyImage}
										style={{
											maxWidth: "10rem",
										}}
									/>
								</div>
							</a>
						))}
				</Marquee>
			</div>
		</section>
	);
};

export default Logos;
