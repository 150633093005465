import { graphql, useStaticQuery } from "gatsby";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import React from "react";
import { Helmet } from "react-helmet";
import Faq from "../components/faq";
import Hero from "../components/hero";
import Layout from "../components/layout";
import OSSection from "../components/other-services-section";

const FAQPage = () => {
	const data = useStaticQuery(graphql`
		query {
			heroImg: wpMediaItem(title: { eq: "Hero-Homepage" }) {
				altText
				mediaDetails {
					height
					width
				}
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}
			sideImg1: wpMediaItem(title: { eq: "Google-image" }) {
				altText
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}

			wpSeoPage(title: { eq: "FAQ" }) {
				contentForHeadTag {
					content
				}
				seo {
					metaDesc
					title
					twitterImage {
						altText
						mediaDetails {
							height
							width
						}
						link
						gatsbyImage(
							width: 1920
							quality: 75
							formats: [WEBP]
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
					opengraphTitle
					opengraphDescription
				}
			}
			allWpFaq {
				nodes {
					faqFields {
						schemaAnswer
						answer
						page
						question
					}
				}
			}

			icon: wpMediaItem(title: { eq: "icon" }) {
				link
			}

			site {
				siteMetadata {
					siteUrl
				}
			}
		}
	`);

	const siteUrl = data.site.siteMetadata.siteUrl;

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: "2",
				name: "FAQ",
				item: {
					url: `${siteUrl}/faq`,
					id: `${siteUrl}/faq`,
				},
			},
		],
	};

	const questions = {
		"@context": "https://schema.org",
		"@type": "FAQPage",
		mainEntity: [
			data.allWpFaq.nodes.map((q, i) => ({
				"@type": "Question",
				name: q.faqFields.question,
				acceptedAnswer: {
					"@type": "Answer",
					text: q.faqFields.schemaAnswer,
				},
			})),
		],
	};

	return (
		<>
			<Helmet>
				{" "}
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
				<script type="application/ld+json">{JSON.stringify(questions)}</script>
			</Helmet>

			{/* <LocalBusinessSchema /> */}
			<GatsbySeo
				title={data.wpSeoPage.seo.title}
				description={data.wpSeoPage.seo.metaDesc}
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}/faq`,
					title: `${data.wpSeoPage.seo.opengraphTitle}`,
					description: `${data.wpSeoPage.seo.opengraphDescription}`,
					images: [
						{
							url: `${data.wpSeoPage.seo.twitterImage.link}`,
							width: `${data.wpSeoPage.seo.twitterImage.mediaDetails.width}`,
							height: `${data.wpSeoPage.seo.twitterImage.mediaDetails.height}`,
							alt: `${data.wpSeoPage.seo.twitterImage.altText}`,
						},
					],
				}}
			/>

			<Layout>
				<section>
					<Hero
						button1="primary-link-dark"
						button1Text="Get in touch"
						button1Link="/contact-us"
						button1Var="white"
						button2={null}
						button3={null}
						backgroundImage={data.heroImg.gatsbyImage}
						title={`Frequently \n<span class="text-secondary">Asked Questions</span>`}
						description="Common questions answered about RJM Digital and our services"
					/>
				</section>

				<Faq page="All" />
				{/* <OSSection
					image={data.sideImg1.gatsbyImage}
					imageAlt={data.sideImg1.altText}
				/> */}
			</Layout>
		</>
	);
};

export default FAQPage;
