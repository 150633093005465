import { graphql, useStaticQuery } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import React from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { InlineWidget } from "react-calendly";
import { Helmet } from "react-helmet";
import { FaFacebook, FaWhatsapp } from "react-icons/fa";
import { SiGooglemybusiness } from "react-icons/si";
import ContactForm from "../../components/contactForm";
import Hero from "../../components/hero";
import Layout from "../../components/layout";
import { Button } from "react-bootstrap";

const ContactUsPageDubai = () => {
	const data = useStaticQuery(graphql`
		query {
			heroImg: wpMediaItem(title: { eq: "Hero-Homepage" }) {
				altText
				mediaDetails {
					height
					width
				}
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}

			wpSeoPage(title: { eq: "Contact Us Dubai" }) {
				contentForHeadTag {
					content
				}
				seo {
					metaDesc
					title
					twitterImage {
						altText
						mediaDetails {
							height
							width
						}
						link
						gatsbyImage(
							width: 1920
							quality: 75
							formats: [WEBP]
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
					opengraphTitle
					opengraphDescription
				}
			}

			site {
				siteMetadata {
					siteUrl
				}
			}
		}
	`);

	const siteUrl = data.site.siteMetadata.siteUrl;

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: "2",
				name: "Dubai",
				item: {
					url: `${siteUrl}/dubai`,
					id: `${siteUrl}/dubai`,
				},
			},
			{
				"@type": "ListItem",
				position: "3",
				name: "Contact Us",
				item: {
					url: `${siteUrl}/dubai/contact-us`,
					id: `${siteUrl}/dubai/contact-us`,
				},
			},
		],
	};

	return (
		<>
			<Helmet>
				{" "}
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>
			{/* <LocalBusinessSchema /> */}
			<GatsbySeo
				title={data.wpSeoPage.seo.title}
				description={data.wpSeoPage.seo.metaDesc}
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}/dubai/contact-us`,
					title: `${data.wpSeoPage.seo.opengraphTitle}`,
					description: `${data.wpSeoPage.seo.opengraphDescription}`,
					images: [
						{
							url: `${data.wpSeoPage.seo.twitterImage.link}`,
							width: `${data.wpSeoPage.seo.twitterImage.mediaDetails.width}`,
							height: `${data.wpSeoPage.seo.twitterImage.mediaDetails.height}`,
							alt: `${data.wpSeoPage.seo.twitterImage.altText}`,
						},
					],
				}}
			/>

			<Layout dubai>
				<section>
					<Hero
						dubai
						button1={null}
						button2={null}
						button3={null}
						backgroundImage={data.heroImg.gatsbyImage}
						title={`CONTACT \n<span class="text-secondary">RJM DIGITAL</span>`}
						description="Send us a message, give us a call or book a meeting using our online calendar."
					/>
				</section>

				<section>
					<iframe
						className="position-relative google-map"
						src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14454.8950719762!2d55.1474062!3d25.07735!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f6d5f7304c865%3A0xa891c5ac1cc02331!2sRJM%20Digital!5e0!3m2!1sen!2suk!4v1676375997015!5m2!1sen!2suk"
						width="100%"
						style="border:0;"
						allowfullscreen=""
						loading="lazy"
						referrerpolicy="no-referrer-when-downgrade"
					></iframe>
				</section>
				<section className="bg-light-grey position-relative ">
					<Container>
						<Row className=" justify-content-between ">
							<Col lg={7} xl={5} className=" ">
								<div className="p-md-5 py-5 position-relative contact-page-section bg-light-grey">
									<h2 className="display-5 text-primary mb-3">Where we are</h2>
									<Row>
										<Col xs={12}>
											<h3 className="text-primary mt-4">Dubai office</h3>

											<a
												className="black-link ssp-regular d-block fs-5"
												target="_blank"
												rel="noreferrer"
												href="https://www.google.com/maps/place/RJM+Digital/@25.07735,55.1474062,15z/data=!4m5!3m4!1s0x0:0xa891c5ac1cc02331!8m2!3d25.07735!4d55.1474062?coh=164777&entry=tt"
											>
												Astrolabs
												<br /> Cluster R<br /> Jumeriah Lake Towers
												<br /> Dubai
											</a>
											{/* <Button
												target="_blank"
												rel="noreferrer"
												href="https://rjm-digital.rjmdigital.net/wp-content/uploads/2023/07/How-to-get-to-RJM-Dubai.pdf"
												style={{ borderRadius: "100px", fontSize: "75%" }}
												className=" px-3 py-1 mt-2"
												variant="secondary"
											>
												How to find us
											</Button> */}
										</Col>
										<Col xs={12}>
											<Row>
												<Col md={6}>
													<h3 className="text-primary mt-4">Email </h3>

													<a
														className="black-link ssp-regular fs-5"
														href="mailto:hello@rjm.digital"
													>
														hello@rjm.digital
													</a>
												</Col>
												<Col md={6}>
													<h3 className="text-primary mt-4">Phone</h3>

													<a
														className="black-link ssp-regular fs-5"
														href="tel:+971525785992"
													>
														0525 785 992
													</a>
												</Col>
											</Row>
										</Col>
									</Row>
									<Row>
										<Col className=" pt-4">
											<div className="d-block  ">
												<ul className="ps-0 mb-1">
													<li className="d-inline-block me-3 ">
														<a
															target="_blank"
															rel="noreferrer"
															className=" black-link fs-3 social-icon whatsapp-link-cta"
															href="https://wa.me/message/TULP6QAAUXB3M1"
														>
															<FaWhatsapp className="whatsapp-link-cta" />
														</a>
													</li>
													<li className="d-inline-block me-3 ">
														<a
															target="_blank"
															rel="noreferrer"
															className=" black-link fs-3 social-icon"
															href="https://www.facebook.com/RJMDigitalUAE/"
														>
															<FaFacebook />
														</a>
													</li>
													<li className="d-inline-block  ">
														<a
															target="_blank"
															rel="noreferrer"
															className=" black-link fs-3 social-icon"
															href="https://goo.gl/maps/yC7zG4ebWL2vjYv5A"
														>
															<SiGooglemybusiness />
														</a>
													</li>
												</ul>
											</div>
										</Col>
									</Row>
								</div>
								<div className="d-flex align-items-center">
									<StaticImage
										src="../../images/Contact-logo-3.svg"
										placeholder="blurred"
										quality={100}
										style={{ maxWidth: "80px" }}
										className="me-3"
									/>
									<StaticImage
										src="../../images/Contact-logo-2.svg"
										placeholder="blurred"
										quality={100}
										style={{ maxWidth: "80px" }}
										className="me-3"
									/>
									<StaticImage
										src="../../images/Contact-logo-1.svg"
										placeholder="blurred"
										quality={100}
										style={{ maxWidth: "80px" }}
									/>
								</div>
								<h2 className="fs-3">We’re taking our meetings online.</h2>
								<p className="pb-5 pb-lg-7">
									Our Zoom meetings mean that we can work with your business, no
									matter where you are in the world.
								</p>
							</Col>
							<Col className=" mt-lg-4" lg={5} xl={6}>
								<h2 className="display-5  text-primary mb-5">
									Request a call back
								</h2>
								<ContactForm />
							</Col>
						</Row>
					</Container>
				</section>
				<section className="pt-5 pt-lg-7 pb-xl-7 bg-light-grey">
					<Container>
						<Row>
							<Col className="text-center">
								{" "}
								<h2 className="display-5 text-primary mb-3">
									We look forward to meeting you!
								</h2>
								<p>
									Book a time and day that works for you through our online
									calendar
								</p>
							</Col>
						</Row>
						<Row>
							<Col className="d-xl-none">
								<InlineWidget
									className="calender"
									styles={{ minWidth: "100%", height: "1150px" }}
									url="https://calendly.com/robbie-rjm-digital/rjm-discovery-meeting-uae?hide_gdpr_banner=1"
								/>
							</Col>
							<Col className="d-none d-xl-block">
								<InlineWidget
									className="calender"
									styles={{ minWidth: "100%", height: "700px" }}
									url="https://calendly.com/robbie-rjm-digital/rjm-discovery-meeting-uae?hide_gdpr_banner=1"
								/>
							</Col>
						</Row>
					</Container>
				</section>
			</Layout>
		</>
	);
};

export default ContactUsPageDubai;
