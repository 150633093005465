import { Link, graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, StaticImage } from "gatsby-plugin-image";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import React from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { InlineWidget } from "react-calendly";
import { Helmet } from "react-helmet";
import { AiOutlineAudit } from "react-icons/ai";
import { BsGraphUp } from "react-icons/bs";
import {
  FaFileCode,
  FaGlobe,
  FaMapMarkerAlt,
  FaMobileAlt,
  FaShoppingBasket,
} from "react-icons/fa";
import { IoMdAnalytics } from "react-icons/io";
import { RiPagesLine } from "react-icons/ri";
import Faq from "../../components/faq";
import Hero from "../../components/hero";
import Layout from "../../components/layout";
import OSSection from "../../components/other-services-section";
import OASection from "../../components/our-approach-section";
import ReviewSection from "../../components/review-section";
import blueOne from "../../images/1-blue.svg";
import blueTwo from "../../images/2-blue.svg";
import blueThree from "../../images/3-blue.svg";

const SEOPageDubai = () => {
  const data = useStaticQuery(graphql`
    query {
      heroImg: wpMediaItem(title: { eq: "Hero-Homepage" }) {
        altText
        mediaDetails {
          height
          width
        }
        link
        gatsbyImage(
          width: 1920
          quality: 75
          formats: [WEBP]
          layout: CONSTRAINED
          placeholder: BLURRED
        )
      }
      sideImg1: wpMediaItem(title: { eq: "Google-image" }) {
        altText
        link
        gatsbyImage(
          width: 1920
          quality: 75
          formats: [WEBP]
          layout: CONSTRAINED
          placeholder: BLURRED
        )
      }

      seoImg: wpMediaItem(title: { eq: "SEARCH ENGINE-OPTIMISATION-IMAGE-2" }) {
        altText
        link
        gatsbyImage(
          width: 1920
          quality: 75
          formats: [WEBP]
          layout: CONSTRAINED
          placeholder: BLURRED
        )
      }
      visibleImg: wpMediaItem(title: { eq: "visible" }) {
        altText
        link
        gatsbyImage(
          width: 1920
          quality: 75
          formats: [WEBP]
          layout: CONSTRAINED
          placeholder: BLURRED
        )
      }
      jasonImg: wpMediaItem(title: { eq: "Jasoncorneslogo" }) {
        altText
        link
        gatsbyImage(
          width: 1920
          quality: 75
          formats: [WEBP]
          layout: CONSTRAINED
          placeholder: BLURRED
        )
      }
      seymourImg: wpMediaItem(title: { eq: "Seymourslogo" }) {
        altText
        link
        gatsbyImage(
          width: 1920
          quality: 75
          formats: [WEBP]
          layout: CONSTRAINED
          placeholder: BLURRED
        )
      }
      sarahImg: wpMediaItem(title: { eq: "sarah-bennett-fullpage-2" }) {
        altText
        link
        gatsbyImage(
          width: 1920
          quality: 75
          formats: [WEBP]
          layout: CONSTRAINED
          placeholder: BLURRED
        )
      }
      wpSeoPage(title: { eq: "SEO Dubai" }) {
        contentForHeadTag {
          content
        }
        seo {
          metaDesc
          title
          twitterImage {
            altText
            mediaDetails {
              height
              width
            }
            link
            gatsbyImage(
              width: 1920
              quality: 75
              formats: [WEBP]
              layout: CONSTRAINED
              placeholder: BLURRED
            )
          }
          opengraphTitle
          opengraphDescription
        }
      }
      icon: wpMediaItem(title: { eq: "icon" }) {
        link
      }

      site {
        siteMetadata {
          siteUrl
        }
      }
    }
  `);

  const siteUrl = data.site.siteMetadata.siteUrl;
  const schema = data.wpSeoPage.contentForHeadTag.content;
  //fix
  const breadcrumb = {
    "@context": "http://schema.org",
    "@type": "BreadcrumbList",
    itemListElement: [
      {
        "@type": "ListItem",
        position: "1",
        name: "Home",
        item: {
          url: `${siteUrl}`,
          id: `${siteUrl}`,
        },
      },
      {
        "@type": "ListItem",
        position: "2",
        name: "Dubai",
        item: {
          url: `${siteUrl}/dubai`,
          id: `${siteUrl}/dubai`,
        },
      },
      {
        "@type": "ListItem",
        position: "3",
        name: "SEO",
        item: {
          url: `${siteUrl}/dubai/seo`,
        },
      },
    ],
  };

  return (
    <>
      <Helmet>
        {" "}
        <script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
      </Helmet>
      <Helmet>
        {" "}
        <script type="application/ld+json">{schema}</script>
      </Helmet>
      {/* <LocalBusinessSchema /> */}
      <GatsbySeo
        title={data.wpSeoPage.seo.title}
        description={data.wpSeoPage.seo.metaDesc}
        language="en"
        openGraph={{
          type: "website",
          url: `${siteUrl}/dubai/seo`,
          title: `${data.wpSeoPage.seo.opengraphTitle}`,
          description: `${data.wpSeoPage.seo.opengraphDescription}`,
          images: [
            {
              url: `${data.wpSeoPage.seo.twitterImage.link}`,
              width: `${data.wpSeoPage.seo.twitterImage.mediaDetails.width}`,
              height: `${data.wpSeoPage.seo.twitterImage.mediaDetails.height}`,
              alt: `${data.wpSeoPage.seo.twitterImage.altText}`,
            },
          ],
        }}
      />

      <Layout dubai>
        <section>
          <Hero
            dubai
            button1="primary-link-dark"
            button1Text="Get in touch"
            button1Link="/dubai/contact-us"
            button1Var="white"
            button2={null}
            button3={null}
            backgroundImage={data.heroImg.gatsbyImage}
            title={`SEO \n<span class="text-secondary">DUBAI</span>`}
            description="Obtaining better quality leads and traffic to your website."
          />
        </section>
        {/* <section className="py-5 py-lg-7 bg-light-grey d-none d-lg-block">
					<Container className="p-5 bg-white">
						<Row className="justify-content-center gy-4">
							<Col className="text-center" md={4} lg={true}>
								<a
									className="text-primary ssp-regular fs-5 border-top-yellow"
									href="#intro"
								>
									Introduction
								</a>
							</Col>
							<Col className="text-center" md={4} lg={true}>
								<a
									className="text-primary ssp-regular fs-5 border-top-yellow"
									href="#benefits"
								>
									Benefits
								</a>
							</Col>
							<Col className="text-center" md={4} lg={true}>
								<a
									className="text-primary ssp-regular fs-5 border-top-yellow"
									href="#results"
								>
									Results
								</a>
							</Col>
							<Col className="text-center" md={4} lg={true}>
								<a
									className="text-primary ssp-regular fs-5 border-top-yellow"
									href="#approach"
								>
									Our approach
								</a>
							</Col>
							<Col className="text-center" md={4} lg={true}>
								<a
									className="text-primary ssp-regular fs-5 border-top-yellow"
									href="#faq"
								>
									FAQ's
								</a>
							</Col>
						</Row>
					</Container>
				</section> */}
        <section id="intro" className="bg-light-grey py-5  py-lg-7">
          <Container>
            <Row className="align-items-center gx-lg-6 gx-xl-7">
              <Col lg={6} className=" ">
                <h2 className="display-5 text-primary mb-4 mb-lg-5">
                  Are you seen on search engines?
                </h2>
                <p>
                  Would your business grow if it attracted better quality leads
                  from your target clients?
                </p>
                <p>
                  We can provide you with a SEO service that is results and data
                  driven. We understand the complexity of the Google algorithm,
                  analyse the reasons why other websites rank high, and
                  customise our approach to enable you to surpass your
                  competitors.
                </p>
                <p>
                  We can assist you in determining if SEO is the appropriate
                  marketing strategy for your business by conducting an SEO
                  audit which involves keyword research, assessing the search
                  volume related to your product or service, and ensuring that
                  your website is optimised to convert traffic into leads. If
                  there are enough potential customers searching for your
                  offerings and we can provide a plan to rank at the top of
                  Google to generate you more leads, why would you not seize
                  this opportunity?
                </p>
              </Col>
              <Col className="mt-4 mt-lg-0" lg={6}>
                <div style={{ overflow: "hidden", borderRadius: "15px" }}>
                  <GatsbyImage
                    className="w-100"
                    image={data.visibleImg.gatsbyImage}
                    alt={data.visibleImg?.altText}
                  />
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        <section id="benefits" className="py-5 py-lg-7 bg-dark-grey">
          <Container className="mb-8">
            <Row>
              <Col>
                <h2 className="display-2 ssp-bold text-white mb-5">
                  BENEFITS OF
                  <span>
                    <br /> <span className="text-light-blue">SEO</span>
                  </span>
                </h2>
              </Col>
            </Row>
            <Row className="gx-xl-9 g-6 justify-content-center">
              <Col className="position-relative" md={6} lg={4}>
                <div
                  style={{ top: "-7rem" }}
                  className="position-absolute text-center w-100 h-100"
                >
                  <img src={blueOne} className="w-md-40 w-30" />
                </div>
                <div className="px-xl-5 pt-5 position-relative">
                  <h3 className="fs-1 text-white">More visibility</h3>
                  <p className="text-white">
                    Being at the top of search engines will bring more customers
                    in through organic searches that are specifically looking
                    for something your Dubai business provides.
                  </p>
                </div>
              </Col>
              <Col className="position-relative" md={6} lg={4}>
                <div
                  style={{ top: "-7rem" }}
                  className="position-absolute text-center w-100 h-100"
                >
                  <img src={blueTwo} className="w-md-40 w-30" />
                </div>
                <div className="px-xl-5 pt-5 position-relative">
                  <h3 className="fs-1 text-white">Increases trust</h3>
                  <p className="text-white">
                    Being ranked No.1 will build trust with potential clients as
                    they trust the search engine to give them the most relevant
                    site for their search term.
                  </p>
                </div>
              </Col>
              <Col className="position-relative " md={6} lg={4}>
                <div
                  style={{ top: "-7rem" }}
                  className="position-absolute w-100 text-center h-100"
                >
                  <img src={blueThree} className="w-md-40 w-30" />
                </div>
                <div className="px-xl-5 pt-5 position-relative">
                  <h3 className="fs-1 text-white">More awareness</h3>
                  <p className="text-white">
                    Brings brand awareness to your Dubai company as people will
                    still see your website at the top regardless of whether they
                    choose to click on it or not.
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        <section id="results" className="bg-light-grey pb-5 pb-lg-7">
          <Container>
            <Row className="align-items-center py-5 ">
              <Col lg={{ span: 7, order: "last" }}>
                <div style={{ overflow: "hidden", borderRadius: "15px" }}>
                  <GatsbyImage
                    className="w-100"
                    image={data.seoImg.gatsbyImage}
                    alt={data.seoImg?.altText}
                  />
                </div>
              </Col>
              <Col lg={5} className="  mt-lg-0">
                <h2 className="display-5 text-primary mb-4">
                  Focused on achieving outcomes
                </h2>
                <p>
                  RJM Digital enjoys working with Dubai businesses that want to
                  grow and we love helping them achieve their goals. Whether it
                  be increasing your online presence, generating more revenue or
                  achieving campaign results, we can help you.
                </p>
                <p>See how we have helped many other businesses do the same…</p>
                <Button
                  className=" cta-btn w-100 w-md-auto mt-4 fs-5 white-link-yellow py-2 px-4 "
                  variant="primary"
                  as={Link}
                  to="/dubai/contact-us"
                >
                  Get in touch
                </Button>
              </Col>
            </Row>
            <Row className="align-items-center our-results mt-md-4 mt-lg-0 py-md-5 py-lg-0 gy-5 gy-lg-0">
              <Col className="text-center px-4 py-md-4" xs={12} lg={true}>
                <div className="bg-jason p-4">
                  <GatsbyImage
                    className="mw-100 "
                    image={data.jasonImg.gatsbyImage}
                    alt={data.jasonImg?.altText}
                  />
                </div>
              </Col>
              <Col lg={true}>
                <h2 className="ssp-bold text-center text-lg-start fs-3">
                  Our results:
                </h2>
              </Col>
              <Col className="text-center" lg={true}>
                <p className="ssp-bold text-primary pb-1 mb-0 display-4">3</p>
                <h3 className="fs-5">Month ROI</h3>
              </Col>
              <Col className="text-center" lg={true}>
                <p className="ssp-bold text-primary pb-1 mb-0 display-4">
                  200%
                </p>
                <h3 className="fs-5">Increase traffic</h3>
              </Col>
              <Col className="text-center" lg={true}>
                <p className="ssp-bold text-primary pb-1 mb-0 display-4">5x</p>
                <h3 className="fs-5">More enquiries</h3>
              </Col>
            </Row>
            <Row className="align-items-center our-results mt-md-5 py-5 py-lg-0 gy-5 gy-lg-0">
              <Col xs={12} className="text-center px-4 py-md-4" lg={true}>
                <div className="bg-black p-4">
                  <GatsbyImage
                    className="mw-100 "
                    image={data.seymourImg.gatsbyImage}
                    alt={data.seymourImg?.altText}
                  />
                </div>
              </Col>
              <Col lg={true}>
                <h2 className="ssp-bold text-center text-lg-start fs-3">
                  Our results:
                </h2>
              </Col>
              <Col className="text-center" lg={true}>
                <p className="ssp-bold text-primary pb-1 mb-0 display-4">5</p>
                <h3 className="fs-5">Locations at #1</h3>
              </Col>
              <Col className="text-center" lg={true}>
                <p className="ssp-bold text-primary pb-1 mb-0 display-4">50%</p>
                <h3 className="fs-5">Increase in CTR</h3>
              </Col>
              <Col className="text-center" lg={true}>
                <p className="ssp-bold text-primary pb-1 mb-0 display-4">2x</p>
                <h3 className="fs-5">More enquiries</h3>
              </Col>
            </Row>
          </Container>
        </section>
        <section className="bg-light-grey py-5 py-lg-7">
          <Container>
            <Row>
              <Col>
                <h2 className="display-5 text-primary mb-5">Types of SEO</h2>
              </Col>
            </Row>
            <Row className="gy-5">
              <Col md={6} lg={4} className="text-md-center">
                <FaMapMarkerAlt className="display-5 text-primary" />
                <h3 className="ssp-bold my-4">Local SEO</h3>
                <p>
                  Be found by people in your local area that are searching for
                  products or services that you offer. 
                </p>
              </Col>
              <Col md={6} lg={4} className="text-md-center">
                <FaShoppingBasket className="display-5 text-primary" />
                <h3 className="ssp-bold my-4">E-Commerce SEO</h3>
                <p>
                  Drive more traffic and sales by optimising your online store
                  for search engines.
                </p>
              </Col>
              <Col md={6} lg={4} className="text-md-center">
                <FaFileCode className="display-5 text-primary" />
                <h3 className="ssp-bold my-4">Technical SEO</h3>
                <p>
                  Make sure the coding of your website is functioning and
                  adhering to Google's best practices (Core Web Vitals)  to
                  support your rankings in search engines.
                </p>
              </Col>
              <Col md={6} lg={4} className="text-md-center">
                <FaGlobe className="display-5 text-primary" />
                <h3 className="ssp-bold my-4">International SEO</h3>
                <p>
                  Let search engines find your website wherever you are in the
                  world, at any time and in any language. 
                </p>
              </Col>
              <Col md={6} lg={4} className="text-md-center">
                <FaMobileAlt className="display-5 text-primary" />
                <h3 className="ssp-bold my-4">Mobile SEO</h3>
                <p>
                  Optimise your websites for mobile devices to improve user
                  experience and engagement from any device.
                </p>
              </Col>
              <Col md={6} lg={4} className="text-md-center">
                <RiPagesLine className="display-5 text-primary" />
                <h3 className="ssp-bold my-4">On-Page SEO</h3>
                <p>
                  Make your content on your landing pages useful to your users
                  as well as adhering to Google’s best practices.
                </p>
              </Col>
              <Col md={6} lg={4} className="text-md-center">
                <BsGraphUp className="display-5 text-primary" />
                <h3 className="ssp-bold my-4">SEO Strategy</h3>
                <p>
                  Create a strategy that will get your business the results it
                  needs. We offer a bespoke result-driven SEO strategy service
                  for your Dubai business.
                </p>
              </Col>
              <Col lg={4} className="text-md-center">
                <IoMdAnalytics className="display-5 text-primary" />
                <h3 className="ssp-bold my-4">SEO Analytics</h3>
                <p>
                  Use analytics to track the progress of your website and  see
                  which SEO strategies are working and which need improvement.
                </p>
              </Col>
              <Col md={6} lg={4} className="text-md-center">
                <AiOutlineAudit className="display-5 text-primary" />
                <h3 className="ssp-bold my-4">SEO Audit</h3>
                <p>
                  Understand what needs to be improved in order to increase your
                  website's visibility. RJM Digital can provide you with a free
                  SEO audit to begin this process.
                </p>
              </Col>
            </Row>
          </Container>
        </section>
        <div id="approach">
          <OASection
            dubai
            imgHeight="60rem"
            image={data.sarahImg.gatsbyImage}
            imageAlt={data.sarahImg?.altText}
            heading1="Results-Driven Strategy"
            brief="RJM Digital knows no two businesses are the same which is why it is essential to have a bespoke SEO strategy. We will arrange a discovery meeting with you to discuss your company and its goals, which enables us to create the most appropriate SEO plan for you."
            heading2="CAMPAIGN DESIGN"
            design="We use a Build ➡ Evaluate ➡ Optimise cycle to ensure we are meeting your SEO expectations and to keep producing the results you are aiming for. As SEO is continually evolving, we will always adapt our approach to ensure we continue to meet your desired outcomes."
            heading3="OPTIMISATION"
            build="Once the initial set-up has been completed and we’re beginning to achieve results, we will begin the optimisation stage. We will update your website when needed due to competitor activity or algorithm updates."
            heading4="CONTINUAL REVIEW"
            launch="RJM Digital will then conduct regular reviews of your website’s SEO performance to ensure it is helping you achieve the results you want it to. We can then adapt your site to keep it up to date with any changes to your business."
          />
        </div>
        <section className="pt-5 pt-lg-7  bg-light-grey">
          <Container>
            <Row id="form">
              <Col className="text-center">
                {" "}
                <h2 className="display-5 text-primary mb-3">
                  Talk to an expert
                </h2>
                <p>
                  Book a time and day that works for you through our online
                  calendar to discuss your project.
                </p>
              </Col>
            </Row>
            <Row>
              <Col className="d-xl-none">
                <InlineWidget
                  className="calender"
                  styles={{ minWidth: "100%", height: "1150px" }}
                  url="https://calendly.com/robbie-rjm-digital/rjm-discovery-meeting-uae?hide_gdpr_banner=1"
                />
              </Col>
              <Col className="d-none d-xl-block">
                <InlineWidget
                  className="calender"
                  styles={{ minWidth: "100%", height: "700px" }}
                  url="https://calendly.com/robbie-rjm-digital/rjm-discovery-meeting-uae?hide_gdpr_banner=1"
                />
              </Col>
            </Row>
          </Container>
        </section>
        {/* <section className="pt-5 pb-md-5 py-lg-7 bg-light-grey">
          <ReviewSection page="Seo" />
        </section> */}
        <div id="faq">
          <Faq page="SEO" />
        </div>
        {/* <OSSection
          dubai
          link1="/training"
          text1="TRAINING"
          link3="/dubai/web-design"
          text3="WEB DESIGN"
          image={data.sideImg1.gatsbyImage}
          imageAlt={data.sideImg1?.altText}
        /> */}
      </Layout>
    </>
  );
};

export default SEOPageDubai;
