import { Link } from "gatsby";
import React from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { BsFillCircleFill } from "react-icons/bs";

const PricingCard = ({
	title,
	subText,
	price,
	monthly,
	features,
	setupFee,
	hPrice,
	middle,
	btnColour,
	dubai,
	btnText,
	btnLink,
}) => {
	const newButtonText = btnText || "Get started";
	const newButtonLink =
		btnLink || (dubai ? "/dubai/contact-us" : "#form");
	return (
		<Col className="mb-5 mb-xl-0" md={6} lg={4}>
			<div
				className={`${
					middle ? "price-box-middle" : ""
				} price-box py-5 px-3 px-lg-5 bg-white`}
			>
				<Row>
					<Col>
						<h2 className="ssp-bold fs-3">{title}</h2>
						<p className="ssp-regular" style={{ fontSize: "90%" }}>
							{subText}
						</p>
						<Button
							style={{
								border: "0px",
								paddingTop: "0.5rem",
								paddingBottom: "0.5rem",
								borderRadius: "0.25rem",
							}}
							variant={btnColour}
							as={Link}
							to={newButtonLink}
							className={`w-100  cta-btn btn pricing-button text-white fs-4  mt-2`}
						>
							{newButtonText}
						</Button>
						<div className={`${monthly ? "" : "d-none"}`}>
							<p
								style={{
									color: "rgba(128, 128, 128, 0.541)",
									fontSize: "100%",
								}}
								className="mt-5 ssp-regular"
							>
								<span
									style={{
										fontSize: "150%",
										color: "black",
									}}
									className="ssp-bold"
								>
									{price}
								</span>
								
							</p>
							<p style={{ fontSize: "100%" }} className={` mt-3 ssp-semibold `}>
								{setupFee} per month for hosting and support
							</p>
							
						</div>
						<div className={`${monthly ? "d-none" : ""}`}>
							<p style={{ fontSize: "100%" }} className="mt-5 ssp-bold">
								<span
									style={{
										fontSize: "150%",
									}}
								>
									{price}
								</span>
							</p>
							<p
								style={{
									fontSize: "90%",
								}}
								className="ssp-semibold"
							>
								Bespoke monthly support and maintenance cost{" "}
								<span className="ssp-bold">{hPrice}</span>pm
							</p>
							{/* <p className="source-light">{`(1 month notice period)`}</p> */}
						</div>

						<div className="features-box">
							{features.map((feature) => {
								return (
									<div className="d-flex align-items-center pt-3">
										<div style={{ minWidth: "8px" }} className="me-2">
											<BsFillCircleFill
												style={{ width: "8px", color: "black" }}
											/>
										</div>
										<p
											style={{ fontSize: "90%" }}
											className="pb-0 mb-0 ssp-regular"
										>
											{feature}
										</p>
									</div>
								);
							})}
						</div>
					</Col>
				</Row>
			</div>
		</Col>
	);
};

export default PricingCard;
