import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import React from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { InlineWidget } from "react-calendly";
import { Helmet } from "react-helmet";
import { BsGraphUpArrow } from "react-icons/bs";
import { FaGoogle, FaPoundSign } from "react-icons/fa";
import { HiSpeakerphone } from "react-icons/hi";
import { MdDesignServices } from "react-icons/md";
import Faq from "../components/faq";
import Hero from "../components/hero";
import Layout from "../components/layout";
import OSSection from "../components/other-services-section";
import OASection from "../components/our-approach-section";
import parse from "html-react-parser";

const TrainingPage = () => {
	const data = useStaticQuery(graphql`
		query {
			heroImg: wpMediaItem(title: { eq: "Hero-Homepage" }) {
				altText
				mediaDetails {
					height
					width
				}
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}
			sarahImg: wpMediaItem(title: { eq: "sarah-bennett-fullpage-2" }) {
				altText
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}
			sideImg1: wpMediaItem(title: { eq: "Google-image" }) {
				altText
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}
			img1: wpMediaItem(title: { eq: "Web-Design-New" }) {
				altText
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}
			trainingImg1: wpMediaItem(title: { eq: "training-image-1" }) {
				altText
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}
			trainingImg2: wpMediaItem(title: { eq: "training-image-2" }) {
				altText
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}
			icon: wpMediaItem(title: { eq: "icon" }) {
				link
			}
			wpSeoPage(title: { eq: "Training" }) {
				contentForHeadTag {
					content
				}
				seo {
					metaDesc
					title
					twitterImage {
						altText
						mediaDetails {
							height
							width
						}
						link
						gatsbyImage(
							width: 1920
							quality: 75
							formats: [WEBP]
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
					opengraphTitle
					opengraphDescription
				}
			}

			wpTrainingPage {
				trainingPageFields {
					bookAMeetingSection {
						description
						title
					}
					fourStepSection {
						button {
							link
							text
						}
						image {
							altText
							gatsbyImage(
								width: 1920
								quality: 75
								formats: [WEBP]
								layout: CONSTRAINED
								placeholder: BLURRED
							)
						}
						title
						step1 {
							title
							description
						}
						step2 {
							title
							description
						}
						step3 {
							title
							description
						}
						step4 {
							title
							description
						}
					}
					heroSection {
						button1 {
							link
							text
						}
						button2 {
							link
							text
						}
						description
						title
					}
					howWeDeliverSection {
						button {
							link
							text
						}
						content
						description
						image1 {
							altText
							gatsbyImage(
								width: 1920
								quality: 75
								formats: [WEBP]
								layout: CONSTRAINED
								placeholder: BLURRED
							)
						}
						image2 {
							altText
							gatsbyImage(
								width: 1920
								quality: 75
								formats: [WEBP]
								layout: CONSTRAINED
								placeholder: BLURRED
							)
						}
						title
					}
					mainBenefitsSection {
						benefit1 {
							title
							description
						}
						benefit2 {
							description
							title
						}
						benefit3 {
							description
							title
						}
						button {
							link
							text
						}
						smallTitle
						title
					}
					mostCommonTrainingSection {
						title
						section1 {
							description
							title
						}
						section2 {
							title
							description
						}
					}
					wantToDevelopSection {
						button1 {
							link
							text
						}
						description
						title
					}
					whatWeOfferSection {
						description
						title
						image {
							altText
							gatsbyImage(
								width: 1920
								quality: 75
								formats: [WEBP]
								layout: CONSTRAINED
								placeholder: BLURRED
							)
						}
					}
				}
			}

			site {
				siteMetadata {
					siteUrl
				}
			}
		}
	`);

	const siteUrl = data.site.siteMetadata.siteUrl;
	const schema = data.wpSeoPage.contentForHeadTag.content;
	const {
		wpTrainingPage: {
			trainingPageFields: {
				bookAMeetingSection,
				fourStepSection,
				heroSection,
				howWeDeliverSection,
				mainBenefitsSection,
				mostCommonTrainingSection,
				wantToDevelopSection,
				whatWeOfferSection,
			},
		},
	} = data;

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: "2",
				name: "Training",
				item: {
					url: `${siteUrl}/training`,
				},
			},
		],
	};

	return (
		<>
			<Helmet>
				{" "}
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>
			<Helmet>
				{" "}
				<script type="application/ld+json">{schema}</script>
			</Helmet>
			{/* <LocalBusinessSchema /> */}
			<GatsbySeo
				title={data.wpSeoPage.seo.title}
				description={data.wpSeoPage.seo.metaDesc}
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}/training`,
					title: `${data.wpSeoPage.seo.opengraphTitle}`,
					description: `${data.wpSeoPage.seo.opengraphDescription}`,
					images: [
						{
							url: `${data.wpSeoPage.seo.twitterImage.link}`,
							width: `${data.wpSeoPage.seo.twitterImage.mediaDetails.width}`,
							height: `${data.wpSeoPage.seo.twitterImage.mediaDetails.height}`,
							alt: `${data.wpSeoPage.seo.twitterImage.altText}`,
						},
					],
				}}
			/>

			<Layout>
				<section>
					<Hero
						customTitle
						button1="primary-link-dark"
						button1Text={heroSection.button1.text}
						button1Link={heroSection.button1.link}
						button1Var="white"
						button2="white-link-yellow"
						button2Text={heroSection.button2.text}
						button2Link={heroSection.button2.link}
						button2Var="primary"
						button3={null}
						backgroundImage={data.heroImg.gatsbyImage}
						title={heroSection.title}
						description={heroSection.description}
					/>
				</section>
				{/* <section className="py-5 py-lg-7 bg-light-grey d-none d-lg-block">
					<Container className="p-5 bg-white">
						<Row className="justify-content-center gy-4">
							<Col className="text-center" md={4} lg={true}>
								<a
									className="text-primary ssp-regular fs-5 border-top-yellow"
									href="#intro"
								>
									Introduction
								</a>
							</Col>
							<Col className="text-center" md={4} lg={true}>
								<a
									className="text-primary ssp-regular fs-5 border-top-yellow"
									href="#training"
								>
									Our training
								</a>
							</Col>
							<Col className="text-center" md={4} lg={true}>
								<a
									className="text-primary ssp-regular fs-5 border-top-yellow"
									href="#deliver"
								>
									How we deliver
								</a>
							</Col>
							<Col className="text-center" md={4} lg={true}>
								<a
									className="text-primary ssp-regular fs-5 border-top-yellow"
									href="#pricing"
								>
									Pricing
								</a>
							</Col>
							<Col className="text-center" md={4} lg={true}>
								<a
									className="text-primary ssp-regular fs-5 border-top-yellow"
									href="#faq"
								>
									FAQ's
								</a>
							</Col>
						</Row>
					</Container>
				</section> */}
				<section id="intro" className="bg-light-grey py-5 py-lg-7">
					<Container>
						<Row className="align-items-center">
							<Col className=" text-center ">
								<div>{parse(wantToDevelopSection.title)}</div>
								<div>{parse(wantToDevelopSection.description)}</div>
								<Button
									className=" cta-btn w-100 w-md-auto mt-4  fs-5 white-link-yellow py-2 px-4 "
									variant="primary"
									href={wantToDevelopSection.button1.link}
								>
									{wantToDevelopSection.button1.text}
								</Button>
							</Col>
						</Row>
					</Container>
				</section>

				<section id="training" className="bg-light-grey py-5 py-lg-7">
					<Container>
						<Row className="align-items-center gx-lg-6 gx-xl-7 gx-6">
							<Col lg={6}>
								<div
									style={{
										overflow: "hidden",
										borderRadius: "15px",
										boxShadow: "0px 3px 99px #00000029",
									}}
								>
									<GatsbyImage
										className="w-100"
										image={whatWeOfferSection.image.gatsbyImage}
										alt={whatWeOfferSection.image.altText}
									/>
								</div>
							</Col>
							<Col lg={6} className=" ">
								<div>{parse(whatWeOfferSection.title)}</div>
								<div>{parse(whatWeOfferSection.description)}</div>
								{/* <Button
									className=" cta-btn w-100 w-md-auto mt-4 fs-5 white-link-yellow py-2 px-4 "
									variant="primary"
									href="#pricing"
								>
									Pricing
								</Button> */}
							</Col>
						</Row>
					</Container>
				</section>
				<section className="bg-primary py-5 py-lg-7">
					<Container>
						<Row className="justify-content-center">
							<Col lg={8} xl={6} className="text-center">
								<p className="ssp-bold text-uppercase text-light-blue">
									{mainBenefitsSection.smallTitle}
								</p>
								<div>{parse(mainBenefitsSection.title)}</div>
							</Col>
						</Row>
						<Row className="mb-5 justify-content-lg-between justify-content-center gy-5">
							<Col md={6} lg={4} xl={3} className="text-md-center ">
								<MdDesignServices className="display-3 text-white" />
								<h3 className="ssp-bold text-white mb-4 mt-2 my-md-4">
									{mainBenefitsSection.benefit1.title}
								</h3>
								<p style={{ fontSize: "100%" }} className="text-white">
									{mainBenefitsSection.benefit1.description}
								</p>
							</Col>
							<Col md={6} lg={4} xl={3} className="text-md-center ">
								<HiSpeakerphone className="display-3 text-white" />
								<h3 className="ssp-bold text-white mb-4 mt-2 my-md-4">
									{mainBenefitsSection.benefit2.title}
								</h3>
								<p style={{ fontSize: "100%" }} className="text-white">
									{mainBenefitsSection.benefit2.description}
								</p>
							</Col>
							<Col md={6} lg={4} xl={3} className="text-md-center ">
								<FaPoundSign className="display-3 text-white" />
								<h3 className="ssp-bold text-white mb-4 mt-2 my-md-4">
									{mainBenefitsSection.benefit3.title}
								</h3>
								<p style={{ fontSize: "100%" }} className="text-white">
									{mainBenefitsSection.benefit3.description}
								</p>
							</Col>
						</Row>
						<Row>
							<Col className="text-center">
								<Button
									className={`mt-4 mt-md-5 fs-5 w-100  w-md-auto cta-btn py-2 px-4 primary-link-dark `}
									variant="white"
									href={mainBenefitsSection.button.link}
								>
									{mainBenefitsSection.button.text}
								</Button>
							</Col>
						</Row>
					</Container>
				</section>
				<section
					id="deliver"
					className="bg-light-grey position-relative pb-5 pb-lg-7"
				>
					<div className="position-relative bg-med-grey">
						<Container className="pt-lg-10 pt-4 pb-5 pb-lg-7">
							<Row className="align-items-center  mt-5">
								<Col lg={6} className=" ">
									<div>{parse(howWeDeliverSection.title)}</div>
									<div>{parse(howWeDeliverSection.description)}</div>
									<Button
										className=" cta-btn w-100 w-md-auto mt-4 fs-5 white-link-yellow py-2 px-4 "
										variant="primary"
										href={howWeDeliverSection.button.link}
									>
										{howWeDeliverSection.button.text}
									</Button>
								</Col>
							</Row>
						</Container>
					</div>
					<div className="position-relative top-5rem">
						<Container>
							<Row className="g-5 gy-lg-3 mt-4 mt-lg-0 justify-content-end">
								<Col lg={4}>
									<div>{parse(howWeDeliverSection.content)}</div>
								</Col>
								<Col lg={4}>
									<div
										className="bg-white"
										style={{
											borderRadius: " 15px",
											boxShadow: "0px 3px 50px #00000012",
											width: "100%",
											height: "25rem",
											overflow: "hidden",
										}}
									>
										<GatsbyImage
											className="w-100 h-100"
											image={howWeDeliverSection.image1.gatsbyImage}
											alt={howWeDeliverSection.image1.altText}
										/>
									</div>
								</Col>
								<Col lg={4}>
									<div
										className="bg-white"
										style={{
											borderRadius: " 15px",
											boxShadow: "0px 3px 50px #00000012",
											width: "100%",
											height: "25rem",
											overflow: "hidden",
										}}
									>
										<GatsbyImage
											className="w-100 h-100"
											image={howWeDeliverSection.image2.gatsbyImage}
											alt={howWeDeliverSection.image2.altText}
										/>
									</div>
								</Col>
							</Row>
						</Container>
					</div>
				</section>
				<div id="approach">
					<OASection
						imgHeight="45rem"
						customHeading
						heading={fourStepSection.title}
						image={fourStepSection.image.gatsbyImage}
						imageAlt={fourStepSection.image.altText}
						heading1={fourStepSection.step1.title}
						heading2={fourStepSection.step2.title}
						heading3={fourStepSection.step3.title}
						heading4={fourStepSection.step4.title}
						launch={fourStepSection.step4.description}
						build={fourStepSection.step3.description}
						design={fourStepSection.step2.description}
						brief={fourStepSection.step1.description}
						buttonUrl={fourStepSection.button.link}
						buttonText={fourStepSection.button.text}
					/>
				</div>
				<section className="pb-5 py-lg-7 bg-light-grey">
					<Container>
						<Row>
							<Col>
								<div>{parse(mostCommonTrainingSection.title)}</div>
							</Col>
						</Row>
						<Row className="g-5">
							<Col className="text-center" lg={6}>
								<BsGraphUpArrow className="display-2 mb-md-5 mb-4 text-primary" />
								<h3 className=" text-center  fs-2   pb-4">
									{mostCommonTrainingSection.section1.title}
								</h3>
								<div>
									{parse(mostCommonTrainingSection.section1.description)}
								</div>
							</Col>
							<Col className="text-center" lg={6}>
								<FaGoogle className="display-2  mb-md-5 mb-4 text-primary" />
								<h3 className=" text-center  fs-2   pb-4">
									{mostCommonTrainingSection.section2.title}
								</h3>
								<div>
									{parse(mostCommonTrainingSection.section2.description)}
								</div>
							</Col>
						</Row>
					</Container>
				</section>
				{/* <section id="pricing">
					<Container>
						<Row>
							<Col>
								<h2 className=" text-center  display-5 mb-5 mt-5   pb-5 text-primary">
									PRICING
								</h2>
							</Col>
						</Row>
						<Row className="justify-content-center ">
							<PricingCardTraining
								btnColour="secondary"
								title="AD - HOC"
								subText="For specific queries about SEO or Google Ads for your website. "
								price="£90"
								hourly
								middle
							/>
							<PricingCardTraining
								btnColour="light-blue"
								title="MONTHLY"
								subText="For businesses who are looking for a long term support and training on upskilling their staff"
								price="£350"
								monthly
								middle
							/>
						</Row>
					</Container>
				</section> */}
				<section className="pt-5 pt-lg-7  bg-light-grey">
					<Container>
						<Row id="form">
							<Col className="text-center">
								<div>{parse(bookAMeetingSection.title)}</div>
								<p>{bookAMeetingSection.description}</p>
							</Col>
						</Row>
						<Row>
							<Col className="d-xl-none">
								<InlineWidget
									className="calender"
									styles={{ minWidth: "100%", height: "1150px" }}
									url="https://calendly.com/robbie-rjm-digital/rjm-discovery-meeting?hide_gdpr_banner=1"
								/>
							</Col>
							<Col className="d-none d-xl-block">
								<InlineWidget
									className="calender"
									styles={{ minWidth: "100%", height: "700px" }}
									url="https://calendly.com/robbie-rjm-digital/rjm-discovery-meeting?hide_gdpr_banner=1"
								/>
							</Col>
						</Row>
					</Container>
				</section>
				<div id="faq">
					<Faq page="Training" />
				</div>
				{/* <OSSection
					link3="/web-design"
					text3="WEB DESIGN"
					image={data.sideImg1.gatsbyImage}
					imageAlt={data.sideImg1.altText}
				/> */}
			</Layout>
		</>
	);
};

export default TrainingPage;
